import React, { useState } from 'react';
import axios from 'axios';
import {
  Box,
  Typography,
  Button,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  TextField,
  FormControlLabel,
  Checkbox,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Snackbar,
  Alert,
  Tooltip,
  MenuItem,
  Select,
  InputLabel,
  FormControl
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import webvtt from 'node-webvtt';

const languages = [
  { label: 'English', code: 'en' },
  { label: 'Spanish', code: 'es' },
  { label: 'French', code: 'fr' },
  { label: 'Italian', code: 'it' },
  { label: 'German', code: 'de' },
];

function SubtitleManager({ videoData, videoIdentifier, password, onUpdateVideoData }) {
  const [notification, setNotification] = useState({ open: false, message: '', severity: 'success' });

  // Dialog for single subtitle management
  const [subtitleDialog, setSubtitleDialog] = useState({
    open: false,
    index: null,
    subtitle: { label: '', srclang: '', src: '', default: false },
  });

  // Dialog for auto-generating subtitles
  const [autoSubtitleDialog, setAutoSubtitleDialog] = useState({
    open: false,
    vttContent: '',
    loading: false,
  });

  // Dialog and states for adding multiple subtitles
  const [openSubtitleUploader, setOpenSubtitleUploader] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(languages[0].code);
  const [vttContent, setVttContent] = useState('');
  const [multiUploadLoading, setMultiUploadLoading] = useState(false);

  const showNotification = (message, severity = 'success') => {
    setNotification({ open: true, message, severity });
  };

  const handleCloseNotification = () => {
    setNotification({ ...notification, open: false });
  };

  const updateVideoData = async (updatedVideoData) => {
    try {
      const response = await axios.post(
        'https://learn.philspeiser.com/api/addupdate',
        {
          ...updatedVideoData,
          identifier: updatedVideoData.identifier || videoIdentifier,
          password,
        },
        {
          headers: { 'Content-Type': 'application/json' }
        }
      );

      if (response.status === 200) {
        onUpdateVideoData(response.data.data);
      }
    } catch (error) {
      console.error('Error updating video data:', error);
      showNotification('Failed to update subtitles.', 'error');
    }
  };

  const handleAddSubtitleClick = () => {
    setSubtitleDialog({
      open: true,
      index: null,
      subtitle: { label: '', srclang: '', src: '', default: false },
    });
  };

  const handleEditSubtitleClick = (index, subtitle) => {
    setSubtitleDialog({ open: true, index, subtitle });
  };

  const handleDeleteSubtitleClick = async (index) => {
    const updatedSubtitles = videoData.subtitles ? [...videoData.subtitles] : [];
    if (index < updatedSubtitles.length) {
      updatedSubtitles.splice(index, 1);
      await updateVideoData({ ...videoData, subtitles: updatedSubtitles });
      showNotification('Subtitle deleted successfully', 'success');
    } else {
      showNotification('Subtitle not found.', 'error');
    }
  };

  const handleSubtitleDialogClose = () => {
    setSubtitleDialog({
      open: false,
      index: null,
      subtitle: { label: '', srclang: '', src: '', default: false },
    });
  };

  const handleSubtitleSubmit = async () => {
    const { index, subtitle } = subtitleDialog;

    if (!subtitle.label || !subtitle.srclang || !subtitle.src) {
      showNotification('Please fill in all subtitle fields.', 'warning');
      return;
    }

    let updatedSubtitles = videoData.subtitles ? [...videoData.subtitles] : [];
    if (index === null) {
      updatedSubtitles.push(subtitle);
    } else if (updatedSubtitles[index]) {
      updatedSubtitles[index] = subtitle;
    } else {
      showNotification('Subtitle not found.', 'error');
      return;
    }

    await updateVideoData({ ...videoData, subtitles: updatedSubtitles });
    handleSubtitleDialogClose();
    showNotification(index === null ? 'Subtitle added successfully' : 'Subtitle updated successfully', 'success');
  };

  const handleAutoGenerateSubtitlesClick = () => {
    setAutoSubtitleDialog({ open: true, vttContent: '', loading: false });
  };

  const handleAutoSubtitleDialogClose = () => {
    setAutoSubtitleDialog({ open: false, vttContent: '', loading: false });
  };

  const handleAutoSubtitleSubmit = async () => {
    const { vttContent } = autoSubtitleDialog;

    if (!vttContent.trim()) {
      showNotification('Please provide VTT content.', 'warning');
      return;
    }

    setAutoSubtitleDialog((prev) => ({ ...prev, loading: true }));
    try {
      // Example endpoint - you will need to handle logic on server side
      await axios.post('https://eopx31rej3ojhld.m.pipedream.net', {
        filename: videoIdentifier,
        subtitledata: vttContent,
      });

      showNotification('Subtitles are being processed and will be added shortly.', 'info');
    } catch (error) {
      console.error('Error initiating subtitle processing:', error);
      showNotification('Failed to initiate subtitle processing.', 'error');
    } finally {
      handleAutoSubtitleDialogClose();
    }
  };

  const handleOpenMultipleSubtitleUploader = () => {
    setOpenSubtitleUploader(true);
  };

  const handleCloseMultipleSubtitleUploader = () => {
    setOpenSubtitleUploader(false);
    setVttContent('');
    setSelectedLanguage(languages[0].code);
    setMultiUploadLoading(false);
  };

  const handleMultipleSubtitleUpload = async () => {
    if (!vttContent.trim()) {
      showNotification('Please provide VTT content.', 'warning');
      return;
    }

    setMultiUploadLoading(true);
    try {
      const fileName = `${videoIdentifier}_${selectedLanguage}`;
      const uploadData = { vttContent, fileName };

      const uploadResponse = await axios.put(
        'https://serve.philspeiser.com/api/uploadvtt', 
        uploadData, 
        { headers: { 'Content-Type': 'application/json' } }
      );

      const { url } = uploadResponse.data;

      const label = languages.find(lang => lang.code === selectedLanguage)?.label || 'Unknown';
      const response = await axios.post(
        'https://learn.philspeiser.com/api/addsubtitle',
        {
          password,
          identifier: videoIdentifier,
          label,
          srclang: selectedLanguage,
          src: url
        },
        {
          headers: { 'Content-Type': 'application/json' }
        }
      );

      if (response.status === 200) {
        showNotification('Subtitle added successfully!', 'success');
        const getAllResponse = await axios.get('https://learn.philspeiser.com/api/getall');
        const allData = getAllResponse.data || {};
        onUpdateVideoData(allData[videoIdentifier]);
      } else {
        showNotification('Failed to upload subtitle.', 'error');
      }
    } catch (err) {
      console.error('Error uploading subtitle:', err);
      showNotification('Failed to upload subtitle.', 'error');
    } finally {
      setMultiUploadLoading(false);
      handleCloseMultipleSubtitleUploader();
    }
  };

  const handleParseVttContent = () => {
    if (!vttContent.trim()) {
      showNotification('No VTT content to parse.', 'warning');
      return;
    }
  
    try {
      // Pre-process the VTT content to fix single-line cues:
      const preProcessed = vttContent.replace(
        /^(\d+)\s+([\d:.]+)\s+-->\s+([\d:.]+)\s+(.*)$/gm,
        '$1\n$2 --> $3\n$4'
      );
  
      const parsed = webvtt.parse(preProcessed, { strict: false });
      console.log('Parsed VTT:', parsed.cues);
  
      if (!parsed.cues || !Array.isArray(parsed.cues) || parsed.cues.length === 0) {
        showNotification('No valid cues found in the VTT content.', 'error');
        return;
      }
      console.log('about to compile');
      console.log('First Cue:', parsed.cues[0]);
      console.log('Cue Count:', parsed.cues.length);
  
      const validCues = parsed.cues.filter(cue =>
        cue &&
        typeof cue.start === 'number' &&
        typeof cue.end === 'number' &&
        typeof cue.text === 'string'
      );
  
      if (validCues.length < parsed.cues.length) {
        console.log('Some cues were filtered out due to invalid structure.');
      }
  
      console.log('Valid cues count:', validCues.length);
  
      // Add metadata if desired
      const meta = {
        Kind: 'captions',
        Language: 'en'
      };
  
      // Compile using the object format:
      const inputForCompile = {
        valid: true,
        meta,
        cues: validCues
      };
  
  
      // Compile all valid cues
      const compiledContent = webvtt.compile(inputForCompile);
      const finalVtt = compiledContent;
      setVttContent(finalVtt);
  
      showNotification('VTT parsed and corrected successfully!', 'success');
    } catch (error) {
      console.error('VTT Parsing Error:', error);
      showNotification('Failed to parse VTT content.', 'error');
    }
  };
  
  
  
  

  return (
    <Box mt={2}>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Subtitles</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {videoData.subtitles && videoData.subtitles.length > 0 ? (
            videoData.subtitles.map((subtitle, index) => (
              <Box key={index} mb={1} display="flex" alignItems="center">
                <Typography variant="body2" sx={{ flex: 1 }}>
                  {`${subtitle.label} (${subtitle.srclang})`}
                </Typography>
                <IconButton
                  color="secondary"
                  onClick={() => handleEditSubtitleClick(index, subtitle)}
                  sx={{ ml: 1 }}
                >
                  <EditIcon />
                </IconButton>
                <IconButton
                  color="error"
                  onClick={() => handleDeleteSubtitleClick(index)}
                  sx={{ ml: 1 }}
                >
                  <DeleteIcon />
                </IconButton>
              </Box>
            ))
          ) : (
            <Typography variant="body2">No subtitles available.</Typography>
          )}
          <Box mt={1} display="flex" gap={1} flexWrap="wrap">
            <Button
              variant="outlined"
              startIcon={<AddIcon />}
              onClick={handleAddSubtitleClick}
            >
              Add Subtitle
            </Button>
            <Button
              variant="outlined"
              startIcon={<AddIcon />}
              onClick={handleAutoGenerateSubtitlesClick}
            >
              Auto-Generate Subtitles
            </Button>
            <Button
              variant="outlined"
              startIcon={<AddIcon />}
              onClick={handleOpenMultipleSubtitleUploader}
            >
              Add Multiple Subtitles
            </Button>
          </Box>
        </AccordionDetails>
      </Accordion>

      {/* Single Subtitle Dialog */}
      <Dialog
        open={subtitleDialog.open}
        onClose={handleSubtitleDialogClose}
      >
        <DialogTitle>{subtitleDialog.index === null ? 'Add Subtitle' : 'Edit Subtitle'}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Label"
                value={subtitleDialog.subtitle.label}
                onChange={(e) =>
                  setSubtitleDialog((prev) => ({
                    ...prev,
                    subtitle: { ...prev.subtitle, label: e.target.value },
                  }))
                }
                variant="outlined"
                margin="dense"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Language Code (e.g., en)"
                value={subtitleDialog.subtitle.srclang}
                onChange={(e) =>
                  setSubtitleDialog((prev) => ({
                    ...prev,
                    subtitle: { ...prev.subtitle, srclang: e.target.value },
                  }))
                }
                variant="outlined"
                margin="dense"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Subtitle URL"
                value={subtitleDialog.subtitle.src}
                onChange={(e) =>
                  setSubtitleDialog((prev) => ({
                    ...prev,
                    subtitle: { ...prev.subtitle, src: e.target.value },
                  }))
                }
                variant="outlined"
                margin="dense"
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={subtitleDialog.subtitle.default}
                    onChange={(e) =>
                      setSubtitleDialog((prev) => ({
                        ...prev,
                        subtitle: { ...prev.subtitle, default: e.target.checked },
                      }))
                    }
                    color="primary"
                  />
                }
                label="Default Subtitle"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleSubtitleDialogClose}
            color="primary"
          >
            Cancel
          </Button>
          <Button onClick={handleSubtitleSubmit} color="primary">
            {subtitleDialog.index === null ? 'Add Subtitle' : 'Update Subtitle'}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Auto-Generate Subtitles Dialog */}
      <Dialog
        open={autoSubtitleDialog.open}
        onClose={handleAutoSubtitleDialogClose}
      >
        <DialogTitle>Auto-Generate Subtitles</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Paste the VTT content (in English) below. The process may take a few minutes.
          </DialogContentText>
          <TextField
            fullWidth
            multiline
            rows={10}
            variant="outlined"
            margin="dense"
            value={autoSubtitleDialog.vttContent}
            onChange={(e) => setAutoSubtitleDialog((prev) => ({ ...prev, vttContent: e.target.value }))}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleAutoSubtitleDialogClose}
            color="primary"
            disabled={autoSubtitleDialog.loading}
          >
            Cancel
          </Button>
          <Button onClick={handleAutoSubtitleSubmit} color="primary" disabled={autoSubtitleDialog.loading}>
            {autoSubtitleDialog.loading ? 'Processing...' : 'Submit'}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Multiple Subtitle Uploader Dialog */}
      <Dialog
        open={openSubtitleUploader}
        onClose={handleCloseMultipleSubtitleUploader}
        fullWidth maxWidth="sm"
      >
        <DialogTitle>Add Multiple Subtitles</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Select a language and paste VTT content below, then click Submit.
          </DialogContentText>
          <Box mt={2}>
            <FormControl fullWidth variant="outlined" margin="dense">
              <InputLabel id="language-select-label">Language</InputLabel>
              <Select
                labelId="language-select-label"
                value={selectedLanguage}
                onChange={(e) => setSelectedLanguage(e.target.value)}
                label="Language"
              >
                {languages.map((lang) => (
                  <MenuItem key={lang.code} value={lang.code}>
                    {lang.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box mt={2}>
            <TextField
              fullWidth
              label="VTT Content"
              multiline
              rows={10}
              variant="outlined"
              value={vttContent}
              onChange={(e) => setVttContent(e.target.value)}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseMultipleSubtitleUploader} disabled={multiUploadLoading} color="primary">
            Cancel
          </Button>
          <Button onClick={handleParseVttContent} color="secondary" disabled={multiUploadLoading}>
            Parse
          </Button>
          <Button onClick={handleMultipleSubtitleUpload} color="primary" disabled={multiUploadLoading}>
            {multiUploadLoading ? 'Uploading...' : 'Submit'}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar Notification */}
      <Snackbar
        open={notification.open}
        autoHideDuration={6000}
        onClose={handleCloseNotification}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseNotification} severity={notification.severity} sx={{ width: '100%' }}>
          {notification.message}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default SubtitleManager;
