'use client'
import React, { useState, useEffect, useRef } from 'react'
import { Button, TextField, Typography, Box, Paper, CircularProgress, IconButton } from '@mui/material'
import { Send as SendIcon, Close as CloseIcon } from '@mui/icons-material'
import ReactMarkdown from 'react-markdown'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import { dark } from 'react-syntax-highlighter/dist/esm/styles/prism'
import chatrobo from './chatrobo.png';

const notionBlack = '#191919'
const notionGrey = '#202020'
const lightGrey = '#333'
const offWhite = '#CFCFCF'
const magenta = '#FF00FF'

export default function TheCB({ identifier, timestamp }) {
  const [messages, setMessages] = useState([])
  const [input, setInput] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [loadingMedia, setLoadingMedia] = useState({})
  const [isChatOpen, setIsChatOpen] = useState(false) // Chat visibility state
  const messagesEndRef = useRef(null)
  const lastMessageRef = useRef(null)
  const timestampRef = useRef(null)

  const scrollToLastMessage = () => {
    lastMessageRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }

  useEffect(scrollToLastMessage, [messages])

  React.useEffect(() => {
    const minutes = Math.floor(timestamp / 60);
    const seconds = timestamp % 60;
  
    const formattedMinutes = String(minutes).padStart(2, '0');
    const formattedSeconds = String(seconds).padStart(2, '0');
  
    const timeString = `${formattedMinutes}:${formattedSeconds}`;
    timestampRef.current = timeString;
  }, [timestamp]);

  React.useEffect(() => {
    if (identifier) {
      setMessages([])
    }

    const user = JSON.parse(localStorage.getItem('user'))
    const displayName = user?.displayName || 'there'
    const firstName = displayName.split(' ')[0]
    
    const initialMessage = {
      role: 'assistant',
      type: 'text',
      content: `Hey ${firstName},\nHow can I help you?`
    }
    
    setMessages([initialMessage])
  }, [identifier])

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (!input.trim()) return

    const userMessage = { role: 'user', type: 'text', content: input }
    setMessages((prev) => [...prev, userMessage])
    setInput('')
    setIsLoading(true)

    try {
      const messageHistory = messages
        .filter((message) => message.role === 'user' || message.role === 'assistant')
        .map((message) => ({
          role: message.role,
          content: message.content,
        }))
  
      const user = JSON.parse(localStorage.getItem('user'))
      const firstName = user?.displayName?.split(' ')[0] || 'User'
          
      const payload = {
        question: input,
        identifier: identifier,
        message_history: messageHistory,
        user_name: firstName,
        timestamp: timestampRef.current,
      }

      const response = await fetch('https://think.philspeiser.com/videochat/chat', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      })

      if (!response.ok) throw new Error('Network response was not ok')

      const reader = response.body.getReader()
      const decoder = new TextDecoder()
  
      let assistantMessage = { role: 'assistant', type: 'text', content: '' }
      setMessages((prev) => [...prev, assistantMessage])

      let fullContent = ''

      const updateAssistantMessage = (chunk) => {
        fullContent += chunk
        setMessages((prev) => {
          const newMessages = [...prev]
          const lastMessageIndex = newMessages.length - 1
          newMessages[lastMessageIndex].content = fullContent
          return newMessages
        })
      }
  
      while (true) {
        const { done, value } = await reader.read()
        if (done) break
        const chunk = decoder.decode(value, { stream: true })
        updateAssistantMessage(chunk)
      }
    } catch (error) {
      console.error('Error:', error)
      const errorMessage = 'Sorry, there was an error processing your request.'
      setMessages((prev) => [...prev, { role: 'assistant', type: 'text', content: errorMessage }])
    } finally {
      setIsLoading(false)
    }
  }

  const renderMessage = (message, index, isLastMessage) => {
    const messageContent = (
      <Box
        ref={isLastMessage ? lastMessageRef : null}
        key={index}
        sx={{
          mb: 2,
          textAlign: message.role === 'user' ? 'right' : 'left',
        }}
      >
        <Box
          sx={{
            display: 'inline-block',
            p: '0px 10px',
            borderRadius: 1,
            backgroundColor: message.role === 'user' ? notionGrey : lightGrey,
            color: 'text.primary',
          }}
        >
          {(() => {
            switch (message.type) {
              case 'text':
                return (
                  <ReactMarkdown
                    components={{
                      code({ node, inline, className, children, ...props }) {
                        const match = /language-(\w+)/.exec(className || '')
                        return !inline && match ? (
                          <SyntaxHighlighter style={dark} language={match[1]} PreTag="div" {...props}>
                            {String(children).replace(/\n$/, '')}
                          </SyntaxHighlighter>
                        ) : (
                          <code className={className} {...props}>
                            {children}
                          </code>
                        )
                      },
                    }}
                  >
                    {message.content}
                  </ReactMarkdown>
                )
              case 'image':
                return (
                  <>
                    {loadingMedia[message.content] && <CircularProgress size={24} />}
                    <img
                      src={message.content}
                      alt="Assistant's image"
                      onLoad={() => setLoadingMedia((prev) => ({ ...prev, [message.content]: false }))}
                      style={{ display: loadingMedia[message.content] ? 'none' : 'block', maxWidth: '100%' }}
                    />
                  </>
                )
              default:
                return <Typography>{message.content}</Typography>
            }
          })()}
        </Box>
      </Box>
    )

    return messageContent
  }

  return (
    <>
      {!isChatOpen && (
        <Button 
          onClick={() => setIsChatOpen(true)}
          variant="contained"
          sx={{
          position: { xs: 'fixed', sm: 'relative'},
          bottom: { xs: '5%', sm: '30px'},
          width:  { xs: '90%', sm: '60px'},
          left: { xs: '5%', sm: '50%'},
          backgroundColor: '#363636',
          color: offWhite,
          '&:hover': {
              backgroundColor: '#e600e6',
            },
          }}

        >
          <img src={chatrobo} alt="CHAT BOT" style={{ height: '40px', marginBottom:'0px'}} />
        </Button>
      )}
      {isChatOpen && (
        <Box sx={{ 
        position: { xs: 'fixed', sm: 'fixed', xl: 'relative'},
        bottom: { xs: '5%', sm: '5%', xl: '600px'},
        width:  { xs: '90%', sm: '450px'},
        left: { xs: '5%',sm: 'calc(95% - 450px)', xl: '50%'},
        height: { xs: '50%', sm: '600px'},
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '100%',
        margin: 'auto',
        p: 2,
        backgroundColor: notionBlack,
        borderRadius: '8px',
        }}>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <IconButton onClick={() => setIsChatOpen(false)} color="inherit">
              <CloseIcon />
            </IconButton>
          </Box>
          <Paper
            elevation={0}
            sx={{
              flexGrow: 1,
              overflowY: 'auto',
              p: 2,
              mb: 2,
              backgroundColor: '#191919',
            }}
          >
            {messages.map((message, index) => 
              renderMessage(message, index, index === messages.length - 1)
            )}
            <div ref={messagesEndRef} />
          </Paper>
          <Box sx={{ position: 'sticky', bottom: 0, backgroundColor: 'background.default', pt: 0 }}>
            <form onSubmit={handleSubmit} style={{ display: 'flex', gap: '8px' }}>
              <TextField
                fullWidth
                variant="outlined"
                value={input}
                onChange={(e) => setInput(e.target.value)}
                placeholder="Type your message here..."
                disabled={isLoading}
              />
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={isLoading}
                sx={{
                  backgroundColor: 'background.paper',
                  color: 'text.primary',
                  '&:hover': {
                    backgroundColor: '#2c2c2c',
                  },
                }}
              >
                {isLoading ? <CircularProgress size={24} /> : <SendIcon />}
              </Button>
            </form>
          </Box>
        </Box>
      )}
    </>
  )
}
