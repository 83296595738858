import React, { useState, useEffect } from 'react';
import axios from 'axios';

const VideoOrderModal = ({ isOpen, onClose }) => {
  const [videoOrder, setVideoOrder] = useState([]);
  const [password, setPassword] = useState(localStorage.getItem('password'));

  useEffect(() => {
    if (isOpen) {
      // Fetch the latest video order array
      axios.get(`https://serve.philspeiser.com/api/getvideoorder?password=${password}`)
        .then((response) => {
          setVideoOrder(response.data);
        })
        .catch((error) => console.error("Error fetching video order:", error));
    }
  }, [isOpen, password]);

  const moveItemUp = (index) => {
    if (index === 0) return;
    const newOrder = [...videoOrder];
    const temp = newOrder[index - 1];
    newOrder[index - 1] = newOrder[index];
    newOrder[index] = temp;
    setVideoOrder(newOrder);
  };

  const moveItemDown = (index) => {
    if (index === videoOrder.length - 1) return;
    const newOrder = [...videoOrder];
    const temp = newOrder[index + 1];
    newOrder[index + 1] = newOrder[index];
    newOrder[index] = temp;
    setVideoOrder(newOrder);
  };

  const handleSave = () => {
    // Update the video order on the server
    axios.patch('https://serve.philspeiser.com/api/updatevideoorder', {
      password,
      value: videoOrder
    })
      .then(response => {
        console.log("Video order updated successfully:", response.data);
        onClose();
      })
      .catch(error => console.error("Error updating video order:", error));
  };

  if (!isOpen) return null;

  return (
    <div style={styles.overlay}>
      <div style={styles.modal}>
        <h2 style={styles.title}>Edit Video Order</h2>

        <div style={{ marginBottom: '20px' }}>
          <input
            type="password"
            placeholder="Password"
            value={password || ''}
            onChange={(e) => setPassword(e.target.value)}
            style={styles.input}
          />
        </div>

        <ul style={styles.list}>
          {videoOrder.map((videoId, index) => (
            <li key={videoId} style={styles.listItem}>
              <span style={styles.videoId}>{videoId}</span>
              <div style={styles.buttons}>
                <button onClick={() => moveItemUp(index)} style={styles.button}>↑</button>
                <button onClick={() => moveItemDown(index)} style={styles.button}>↓</button>
              </div>
            </li>
          ))}
        </ul>

        <button onClick={handleSave} style={styles.saveButton}>
          Save Changes
        </button>
        <button onClick={onClose} style={styles.closeButton}>
          Close
        </button>
      </div>
    </div>
  );
};

const styles = {
  overlay: {
    position: 'fixed',
    top: 0, left: 0, width: '100%', height: '100%',
    backgroundColor: 'rgba(0,0,0,0.8)',
    zIndex: 2000,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '20px',
    overflowY: 'auto',
  },
  modal: {
    backgroundColor: '#222',
    padding: '30px',
    borderRadius: '10px',
    maxWidth: '600px',
    width: '100%',
    maxHeight: '90vh',
    overflowY: 'auto',
  },
  title: {
    marginBottom: '20px',
    textAlign: 'center',
    color: '#fff',
    fontSize: '24px',
  },
  input: {
    width: '100%',
    padding: '10px',
    borderRadius: '5px',
    border: '1px solid #555',
    backgroundColor: '#333',
    color: '#fff',
    marginBottom: '20px',
  },
  list: {
    listStyle: 'none',
    padding: 0,
    margin: '0 0 20px 0',
    color: '#fff',
  },
  listItem: {
    backgroundColor: '#333',
    marginBottom: '10px',
    padding: '10px',
    borderRadius: '5px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  videoId: {
    fontSize: '16px',
    color: '#fff',
  },
  buttons: {
    display: 'flex',
    gap: '5px',
  },
  button: {
    padding: '5px 10px',
    borderRadius: '5px',
    backgroundColor: '#444',
    color: '#fff',
    border: 'none',
    cursor: 'pointer',
  },
  saveButton: {
    width: '100%',
    padding: '10px',
    borderRadius: '5px',
    backgroundColor: '#333',
    color: '#fff',
    border: 'none',
    cursor: 'pointer',
    marginBottom: '15px',
    fontSize: '14px',
  },
  closeButton: {
    width: '100px',
    padding: '10px',
    borderRadius: '5px',
    backgroundColor: '#333',
    color: '#fff',
    border: 'none',
    cursor: 'pointer',
    fontSize: '14px',
  },
};

export default VideoOrderModal;
