import React, { useState, useEffect } from 'react';
import axios from 'axios';

const BotContext = ({ isOpen, onClose }) => {
    const [jsonData, setJsonData] = useState({});
    const [newIdentifier, setNewIdentifier] = useState('');
    const [password, setPassword] = useState(localStorage.getItem('password'));
    const [expandedIdentifiers, setExpandedIdentifiers] = useState({});
    const [newTimestampData, setNewTimestampData] = useState({});

    useEffect(() => {
        if (isOpen) {
            // Fetch JSON content
            axios.get('https://think.philspeiser.com/videochat/contexts')
                .then((response) => setJsonData(response.data))
                .catch((error) => console.error("Error fetching data:", error));
        }
    }, [isOpen]);

    const toggleExpand = (identifier) => {
        setExpandedIdentifiers(prev => ({
            ...prev,
            [identifier]: !prev[identifier]
        }));
    };

    const handleAddIdentifier = () => {
        if (newIdentifier.trim() === '') return;
        setJsonData(prev => ({
            ...prev,
            [newIdentifier]: {
                context: '',
                video_data: {}
            }
        }));
        setExpandedIdentifiers(prev => ({
            ...prev,
            [newIdentifier]: true
        }));
        setNewIdentifier('');
    };

    const handleContextChange = (id, value) => {
        setJsonData(prev => ({
            ...prev,
            [id]: {
                ...prev[id],
                context: value
            }
        }));
    };

    const handleTimestampChange = (id, timestamp, value) => {
        setJsonData(prev => ({
            ...prev,
            [id]: {
                ...prev[id],
                video_data: {
                    ...prev[id].video_data,
                    [timestamp]: value
                }
            }
        }));
    };

    const handleAddTimestamp = (identifier) => {
        const { timestamp, description } = newTimestampData[identifier] || {};
        if (identifier && timestamp && description) {
            setJsonData(prev => ({
                ...prev,
                [identifier]: {
                    ...prev[identifier],
                    video_data: {
                        ...prev[identifier].video_data,
                        [timestamp]: description
                    }
                }
            }));
            // Clear the new timestamp data for this identifier
            setNewTimestampData(prev => ({
                ...prev,
                [identifier]: { timestamp: '', description: '' }
            }));
        }
    };

    const handleSubmit = () => {
        axios.post('https://think.philspeiser.com/videochat/contexts', {
            password,
            data: jsonData
        })
            .then(response => {
                console.log("Data saved successfully:", response);
                onClose();
            })
            .catch(error => console.error("Error saving data:", error));
    };

    if (!isOpen) return null;

    return (
        <div style={styles.overlay}>
            <div style={styles.modal}>
                <h2 style={styles.title}>Edit Bot Context</h2>

                <div style={styles.inputGroup}>
                    <input
                        type="text"
                        placeholder="New Video Identifier"
                        value={newIdentifier}
                        onChange={(e) => setNewIdentifier(e.target.value)}
                        style={styles.input}
                    />
                    <button onClick={handleAddIdentifier} style={styles.addButton}>
                        Add Video
                    </button>
                </div>

                {Object.keys(jsonData).map((identifier) => (
                    <div key={identifier} style={styles.section}>
                        <div style={styles.sectionHeader} onClick={() => toggleExpand(identifier)}>
                            <h3 style={styles.sectionTitle}>{identifier}</h3>
                            <span style={styles.expandIcon}>
                                {expandedIdentifiers[identifier] ? '▲' : '▼'}
                            </span>
                        </div>
                        {expandedIdentifiers[identifier] && (
                            <div style={styles.sectionContent}>
                                <textarea
                                    placeholder="Context"
                                    value={jsonData[identifier].context}
                                    onChange={(e) => handleContextChange(identifier, e.target.value)}
                                    style={styles.textarea}
                                />
                                <h4 style={styles.subtitle}>Video Data:</h4>
                                {Object.entries(jsonData[identifier].video_data).map(([timestamp, text]) => (
                                    <div key={timestamp} style={styles.timestampGroup}>
                                        <input
                                            type="text"
                                            placeholder="Timestamp"
                                            value={timestamp}
                                            readOnly
                                            style={styles.input}
                                        />
                                        <textarea
                                            placeholder="Description"
                                            value={text}
                                            onChange={(e) => handleTimestampChange(identifier, timestamp, e.target.value)}
                                            style={styles.textarea}
                                        />
                                    </div>
                                ))}
                                <h4 style={styles.subtitle}>Add New Timestamp</h4>
                                <div style={styles.timestampGroup}>
                                    <input
                                        type="text"
                                        placeholder="New Timestamp"
                                        value={(newTimestampData[identifier] && newTimestampData[identifier].timestamp) || ''}
                                        onChange={(e) => setNewTimestampData(prev => ({
                                            ...prev,
                                            [identifier]: {
                                                ...prev[identifier],
                                                timestamp: e.target.value
                                            }
                                        }))}
                                        style={styles.input}
                                    />
                                    <textarea
                                        placeholder="Description for Timestamp"
                                        value={(newTimestampData[identifier] && newTimestampData[identifier].description) || ''}
                                        onChange={(e) => setNewTimestampData(prev => ({
                                            ...prev,
                                            [identifier]: {
                                                ...prev[identifier],
                                                description: e.target.value
                                            }
                                        }))}
                                        style={styles.textarea}
                                    />
                                    <button onClick={() => handleAddTimestamp(identifier)} style={styles.addButton}>
                                        Add Timestamp
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                ))}

                <button onClick={handleSubmit} style={styles.saveButton}>
                    Save Changes
                </button>
                <button onClick={onClose} style={styles.closeButton}>
                    Close
                </button>
            </div>
        </div>
    );
};

const styles = {
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        zIndex: 2000,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '20px',
        overflowY: 'auto',
    },
    modal: {
        backgroundColor: '#222',
        padding: '30px',
        borderRadius: '10px',
        maxWidth: '1200px',
        width: '100%',
        maxHeight: '90vh',
        overflowY: 'auto',
    },
    title: {
        marginBottom: '30px',
        textAlign: 'center',
        color: '#fff',
        fontSize: '28px',
    },
    inputGroup: {
        display: 'flex',
        marginBottom: '30px',
    },
    input: {
        flex: 1,
        padding: '15px',
        borderRadius: '5px',
        border: '1px solid #555',
        marginRight: '10px',
        backgroundColor: '#333',
        color: '#fff',
    },
    addButton: {
        padding: '15px 25px',
        borderRadius: '5px',
        backgroundColor: '#444',
        color: '#fff',
        border: 'none',
        cursor: 'pointer',
    },
    section: {
        marginBottom: '30px',
        border: '1px solid #555',
        borderRadius: '5px',
        backgroundColor: '#333',
    },
    sectionHeader: {
        padding: '15px',
        backgroundColor: '#444',
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    sectionTitle: {
        margin: 0,
        color: '#fff',
        fontSize: '16px',
    },
    expandIcon: {
        fontSize: '16px',
        color: '#fff',
    },
    sectionContent: {
        padding: '15px',
    },
    textarea: {
        width: '100%',
        padding: '15px',
        borderRadius: '5px',
        border: '1px solid #555',
        minHeight: '100px',
        marginBottom: '15px',
        backgroundColor: '#333',
        color: '#fff',
    },
    subtitle: {
        marginBottom: '15px',
        marginTop: '15px',
        color: '#fff',
        fontSize: '14px',
    },
    timestampGroup: {
        marginBottom: '15px',
    },
    saveButton: {
        width: '100%',
        padding: '10px',
        borderRadius: '5px',
        backgroundColor: '#333',
        color: '#fff',
        border: 'none',
        cursor: 'pointer',
        marginBottom: '15px',
        fontSize: '14px',
    },
    closeButton: {
        width: '100px',
        padding: '10px',
        borderRadius: '5px',
        backgroundColor: '#333',
        color: '#fff',
        border: 'none',
        cursor: 'pointer',
        fontSize: '14px',
        position: 'fixed',
        top: '20px',    
        right: '20px',

    },
};

export default BotContext;
