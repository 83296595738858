import React from 'react';
import { Box, Typography } from '@mui/material';

function Footer() {
  return (
    <Box 
      sx={{ 
        textAlign: 'center', 
        borderTop: '1px solid #2b2a2a', 
        zIndex: 9000,
        position: 'fixed',
        bottom: '0',
        left: '0',
        margin: '0',
        padding: '0',
        width: '100vw',
        backgroundColor: '#1d1d1d',
        color: '#fff',
        fontSize: '0.5rem',
        '& a': {
          color: 'white',
          fontSize: '0.8rem',
          textDecoration: 'none',
          '&:hover': {
            color: '#e600e6',
          },
        },
      }}
    >
      <Typography variant="body2" color="text.secondary" style={{ padding: '0.5rem' }}>
        <a href="https://philspeiser.com/the_circle" target="_blank" rel="noreferrer"> UNLOCK ALL VIDEOS!</a> 
      
      </Typography>
    </Box>
  );
}

export default Footer;