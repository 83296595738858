import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import {
  Card,
  CardMedia,
  Box,
  Typography,
  Snackbar,
  Alert,
  IconButton,
  Chip,
  TextField,
  Button,
  useMediaQuery,
  InputAdornment,
  LinearProgress,
  Backdrop,
  CircularProgress
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import LockIcon from '@mui/icons-material/Lock';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import VPlayerOverlay from './VPlayerOverlay';
import pslogo from './pslogo.png';
import chatrobo from './chatrobo5.png';
import LoadingAnimation from './LoadingAnimation';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Footer from './Footer';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#90caf9',
    },
    secondary: {
      main: '#f48fb1',
    },
    background: {
      default: '#121212',
      paper: '#1e1e1e',
    },
    text: {
      primary: '#ffffff',
      secondary: '#b0b0b0',
    },
  },
  typography: {
    fontFamily: 'Oswald, sans-serif',
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          backgroundColor: '#2e2e2e',
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: '#90caf9',
          },
        },
        input: {
          "&:-webkit-autofill": {
            WebkitBoxShadow: "0 0 0 100px #2e2e2e inset",
            WebkitTextFillColor: "#ffffff",
          },
        },
      },
    },
  },
});

const Home = () => {
  const [userData, setUserData] = useState(null);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [userOwned, setUserOwned] = useState({});
  const [videoData, setVideoData] = useState({});
  const [notification, setNotification] = useState({
    open: false,
    message: '',
    severity: 'success',
  });
  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isOverlayOpen, setIsOverlayOpen] = useState(false);
  const [selectedVideoData, setSelectedVideoData] = useState(null);
  const [progressData, setProgressData] = useState({});
  const [FeaturedVideo, setFeaturedVideo] = useState('');
  const [FeaturedVideoOffset, setFeaturedVideoOffset] = useState(0);
  const [FeaturedVideoOffsetxs, setFeaturedVideoOffsetxs] = useState(0);
  const [showPassword, setShowPassword] = useState(false);
  const [videoOrder, setVideoOrder] = useState([]);
  const [secret, setSecret] = useState(false);

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));

  // Track when loading started
  const loadingStartTime = useRef(null);

  const startLoading = () => {
    if (!loadingStartTime.current) {
      loadingStartTime.current = Date.now();
    }
    setLoading(true);
  };

  const stopLoading = () => {
    const now = Date.now();
    const elapsed = now - loadingStartTime.current;
    const minDuration = 2500; // At least 2 seconds of animation
    if (elapsed < minDuration) {
      setTimeout(() => {
        setLoading(false);
        loadingStartTime.current = null;
      }, minDuration - elapsed);
    } else {
      setLoading(false);
      loadingStartTime.current = null;
    }
  };

  const handlePasswordVisibilityToggle = () => {
    setShowPassword((prev) => !prev);
  };

  const fetchFeaturedVideo = async () => {
    console.log('fetching featured video');
    try {
      const response = await axios.get(`https://serve.philspeiser.com/api/getconfig`);
      setFeaturedVideo(response.data.featuredvideo);
      setFeaturedVideoOffset(response.data.featuredvideooffset);
      setFeaturedVideoOffsetxs(response.data.featuredvideooffsetxs);
      setVideoOrder(response.data.videoOrder);
    } catch (err) {
      setNotification({
        open: true,
        message: 'Failed to fetch featured video.',
        severity: 'error',
      });
    }
  };

  const fetchProgressData = async () => {
    console.log('fetching progress data');
    try {
      const response = await axios.get(`https://serve.philspeiser.com/api/getvideoprogress?email=${userData?.email}&name=${userData?.displayName}`);
      setProgressData(response.data);
    } catch (err) {
      setNotification({
        open: true,
        message: 'Failed to fetch user progress data.',
        severity: 'error',
      });
    }
  };

  const fetchVideoData = async () => {
    console.log('fetching video data');
    try {
      const response = await axios.get('https://learn.philspeiser.com/api/getall');
      const fetchedData = response.data || {};
  
      const updatedData = Object.keys(fetchedData).reduce((acc, key) => {
        const video = fetchedData[key];
        if (video.products) {
          video.products = video.products.split(/\s*,\s*/).map((product) => product.trim());
        } else {
          video.products = [];
        }
  
        let owned = false;
        if (video.public === 'true') {
          owned = true;
        } else if (userOwned.subscription === 'active') {
          owned = true;
        } else if (userOwned.products) {
          owned = video.products.some((product) => userOwned.products.includes(product));
        }
  
        acc[key] = { ...video, owned };
        return acc;
      }, {});
  
      setVideoData(updatedData);
  
      // Extract and sort product tags alphabetically
      const allProducts = [
        ...new Set(Object.values(updatedData).flatMap((video) => video.products)),
      ].sort((a, b) => a.localeCompare(b));
      setProducts(allProducts);
  
      const categoryList = [...new Set(Object.values(updatedData).map((video) => video.category))];
      categoryList.sort((a, b) => {
        if (a === "MASTERCLASS") return -1;
        if (b === "MASTERCLASS") return 1;
  
        if (a === "WALKTHROUGH") return -1;
        if (b === "WALKTHROUGH") return 1;
  
        if (a === "TUTORIAL") return -1;
        if (b === "TUTORIAL") return 1;
  
        if (a === "QUICK TIP") return -1;
        if (b === "QUICK TIP") return 1;
  
        return 0;
      });
  
      setCategories(categoryList);
    } catch (err) {
      setNotification({
        open: true,
        message: 'Failed to fetch video data.',
        severity: 'error',
      });
    } finally {
      await fetchFeaturedVideo();
      await fetchProgressData();
      stopLoading();
    }
  };
  

  const fetchUserOwned = async () => {
    console.log('fetching user owned');
    try {
      const response = await axios.post('https://psproducts.vercel.app/api/ownedfortoken', {
        token: userData.token,
      });
      setUserOwned(response.data);
    } catch (err) {
      setNotification({
        open: true,
        message: 'Failed to fetch user owned data.',
        severity: 'error',
      });
      // Data fetch failed, no data to show, so stop loading
      stopLoading();
    }
  };

  const attemptTokenLogin = async () => {
    console.log('token login');
    const urlParams = new URLSearchParams(window.location.search);
    const urlEmail = urlParams.get('email');
    const urlToken = urlParams.get('token');

    if (!userData && urlEmail && urlToken) {
      startLoading();
      try {
        const response = await axios.post('https://psproducts.vercel.app/api/tokenauth', {
          email: urlEmail,
          token: urlToken,
        });

        const newUserData = {
          email: response.data.user.username,
          displayName: response.data.user.first_name,
          token: response.data.user.token,
        };
        localStorage.setItem('userData', JSON.stringify(newUserData));
        setUserData(newUserData);

        const url = new URL(window.location);
        url.searchParams.delete('email');
        url.searchParams.delete('token');
        window.history.replaceState({}, document.title, url.pathname);

        setNotification({
          open: true,
          message: 'Login successful.',
          severity: 'success',
        });
      } catch (err) {
        // token login failed => show login
        stopLoading();
      }
    } else {
      // No token-based login
      // If no userData, then just show login form
      if (!userData) {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    const storedUserData = localStorage.getItem('userData');
    if (storedUserData) {
      // We have userData => start loading and fetch data
      startLoading();
      setUserData(JSON.parse(storedUserData));
    } else {
      // No userData, try token login
      attemptTokenLogin();
    }
  }, []);

  useEffect(() => {
    if (userData) {
      // Have user data => fetch owned products
      fetchUserOwned();
    }
  }, [userData]);

  useEffect(() => {
    if (userOwned.subscription) {
      // Subscription fetched => fetch videos
      fetchVideoData();
    }
  }, [userOwned]);

  useEffect(() => {
    if (isOverlayOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [isOverlayOpen]);

  const handleLogin = async (e) => {
    e.preventDefault();
    startLoading();
    try {
      const response = await axios.post('https://psproducts.vercel.app/api/auth', {
        email: email,
        password: password,
      });
      
      const userDataResponse = {
        email: response.data.user.username,
        displayName: response.data.user.first_name,
        token: response.data.user.token,
      };

      localStorage.setItem('userData', JSON.stringify(userDataResponse));
      setUserData(userDataResponse);
      setNotification({
        open: true,
        message: 'Login successful.',
        severity: 'success',
      });
      // After login owned and videos will be fetched, stopLoading will be called then
    } catch (err) {
      setNotification({
        open: true,
        message: 'Login failed. Please check your credentials.',
        severity: 'error',
      });
      // No data to fetch => stop loading
      stopLoading();
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('userData');
    setUserData(null);
    window.location.reload();
  };

  const handleOpenOverlay = (video) => {
    if (video.owned) {
      const progress = progressData[video.identifier] || 0;
      setSelectedVideoData({ ...video, progress });
      setIsOverlayOpen(true);
    }
  };

  const handleCloseOverlay = () => {
    setIsOverlayOpen(false);
    setSelectedVideoData(null);
    fetchProgressData();
  };

  const handleProductTagClick = (product) => {
    setSelectedProducts((prevSelected) =>
      prevSelected.includes(product)
        ? prevSelected.filter((item) => item !== product)
        : [...prevSelected, product]
    );
  };

  const sortVideos = (videos, order) => {
    const orderMap = new Map();
    order.forEach((id, index) => {
      orderMap.set(id, index);
    });

    return [...videos].sort((a, b) => {
      const aIndex = orderMap.has(a.identifier) ? orderMap.get(a.identifier) : Infinity;
      const bIndex = orderMap.has(b.identifier) ? orderMap.get(b.identifier) : Infinity;
      return aIndex - bIndex;
    });
  };

 

  const moveOwnedToTop = (videos) => {
    const owned = videos.filter(video => video.owned);
    const notOwned = videos.filter(video => !video.owned);
    return [...owned, ...notOwned];
  };

  const filteredVideos = Object.values(videoData).filter((video) => {
    return video.frontend === 'true' &&
      (selectedProducts.length === 0 || video.products.some((product) => selectedProducts.includes(product)));
  });

  const sortedVideos = moveOwnedToTop(sortVideos(filteredVideos, videoOrder));


  const renderMainContent = () => (
    <Box sx={{ textAlign: 'center', padding: '2rem', margin:'auto', marginTop: '30px', maxWidth:'1500px' }}>
       
       {userOwned.subscription != 'active' && (
       <Footer />
        )}

      {FeaturedVideo && (  
        <Card sx={{ position: 'relative', width: '100%', height: {xs:'150px',sm:'300px'}, overflow: 'hidden', marginBottom: '30px'}}>
          <CardMedia
            component="img"
            image={videoData[FeaturedVideo]?.thumbnailUrl}
            title={videoData[FeaturedVideo]?.title}
            sx={{
              width: '100%',
              position: 'absolute',
              bottom: {xs:FeaturedVideoOffsetxs,md: FeaturedVideoOffset},
              objectFit: 'cover',
            }}
          />

          <Typography
            variant="h3"
            component="div"
            sx={{
              textShadow: '0px 0px 10px black',
              fontSize: {xs:'1.5rem',sm:'2.5rem'},
              fontWeight: '900',
              position: 'absolute',
              top: {xs:'calc(25% - 1rem)',sm:'calc(25% - 2.5rem)'},
              left: 0,
              right: 0,
              backgroundColor: 'none',
              color: 'white',
              padding: 0,
              textAlign: 'center',
            }}
          >
            {videoData[FeaturedVideo]?.title}
          </Typography>
          <Typography
            variant="h6"
            component="div"
            sx={{
              textShadow: '0px 0px 10px black',
              fontWeight: '400',
              position: 'absolute',
              fontSize: {xs:'1rem',sm:'1.5rem'},
              bottom: {xs:'calc(25% - 0.5rem)',sm:'calc(25% - 1.5rem)'},
              left: 0,
              right: 0,
              backgroundColor: 'none',
              color: 'white',
              padding: 0,
              textAlign: 'center',
            }}
          >
            {videoData[FeaturedVideo]?.description}
          </Typography>
          <Typography
            variant="h6"
            component="div"
            sx={{
              textShadow: '0px 0px 10px black',
              fontWeight: '400',
              position: 'absolute',
              top: {xs:'0px', sm:'5px'},
              left: {xs:'0px', sm:'5px'},
              backgroundColor: 'rgba(9, 9, 9, 0.9)',
              color: 'white',
              padding: {xs:'0px', sm:'0px'},
              textAlign: 'center',
              borderRadius: {xs:'0px', sm:'5px'},
              fontSize:{xs:'10px', sm:'18px'},
              width:{xs:'70px', sm:'150px'},
              height:{xs:'15px', sm:'30px'},
            }}
          >
            FEATURED VIDEO
          </Typography>

          {videoData[FeaturedVideo]?.owned ? (
            <IconButton
              className="play-button"
              onClick={() => handleOpenOverlay(videoData[FeaturedVideo])}
              sx={{
                position: 'absolute',
                border: '2px solid white',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                color: 'white',
                backgroundColor: 'rgba(0, 0, 0, 0.6)',
                opacity: 1,
                transition: 'transform 0.3s, background-color 0.3s, opacity 0.3s',
                '&:hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.8)',
                  transform: 'translate(-50%, -50%) scale(1.2)',
                },
              }}
            >
              <PlayArrowIcon sx={{ fontSize: {xs:20, sm:40}, }} />
            </IconButton>
          ) : (
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                color: 'white',
                padding: '1rem',
                textAlign: 'center',
              }}
            >
              <LockIcon sx={{ fontSize: 40, color: 'white' }} />
              <Typography variant="body2" component="div" sx={{ marginTop: '0.5rem' }}>
                Get {videoData[FeaturedVideo]?.products.join(', ')} to unlock this video
              </Typography>
            </Box>
          )}
        </Card> 
      )}
      <Box
  sx={{
    marginBottom: '2rem',
    marginTop: '0rem',
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    gap: 1,
    // Apply mobile-specific styles using breakpoints
    [theme.breakpoints.down('sm')]: {
      height: '45px',
      overflowY: 'auto',
      flexWrap: 'nowrap',
      justifyContent: 'flex-start',
    },
  }}
>
  {products.map((product) => (
    <Chip
      key={product}
      label={product}
      clickable
      color={selectedProducts.includes(product) ? 'primary' : 'default'}
      onClick={() => handleProductTagClick(product)}
    />
  ))}
</Box>

      {categories.map((category) => (
        <Box key={category} sx={{ marginBottom: '2rem' }}>
          <Typography
            variant="h5"
            component="div"
            sx={{ marginBottom: '1rem', textAlign: 'left' }}
          >
            {category}
          </Typography>
          <Box
            sx={{
              display: 'flex',
              overflowX: 'auto',
              paddingBottom: '1rem',
              '&::-webkit-scrollbar': {
                display: 'none',
              },
            }}
          >
            {sortedVideos
              .filter((video) => video.category === category)
              .map((video) => {
                const progress = progressData[video.identifier] || 0;
                const isWatched = progress > 95;

                return (
                  <Card
                    key={video.identifier}
                    sx={{
                      minWidth: 245,
                      maxWidth: 245,
                      marginRight: '1rem',
                      backgroundColor: 'background.paper',
                      position: 'relative',
                      '&:hover .description': {
                        opacity: 1,
                        transform: 'translateY(0)',
                      },
                    }}
                  >
                    <Box
                      sx={{
                        position: 'relative',
                        width: '100%',
                        aspectRatio: '4 / 5',
                      }}
                    >
                      <CardMedia
                        component="img"
                        alt={video.title}
                        image={video.minithumbnailUrl}
                        title={video.title}
                        sx={{
                          width: '100%',
                          height: '100%',
                          objectFit: 'cover',
                        }}
                      />
                      <Box
                        sx={{
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          right: 0,
                          height: '100%',
                          backgroundColor: 'rgba(0, 0, 0, 0.2)',
                          color: 'white',
                          padding: '0.5rem',
                          textAlign: 'left',
                        }}
                      >
                        <Typography
                          variant="h4"
                          component="div"
                          style={{
                            textShadow: '0px 0px 10px black',
                            fontWeight: '900',
                          }}
                        >
                        {video.title}
                        </Typography>
                        
                          {!video.chatbot == "" && (
                           <img src={chatrobo} alt="CHAT BOT" style={{ height: '25px', marginBottom:'0px', position:'absolute', top:'2', right:'2'}} />
                          )}
                      </Box>
                      <Box
                        className="description"
                        sx={{
                          position: 'absolute',
                          bottom: 0,
                          left: 0,
                          right: 0,
                          backgroundColor: 'rgba(0, 0, 0, 0.8)',
                          color: 'white',
                          padding: '0.5rem',
                          paddingBottom: '40px',
                          textAlign: 'left',
                          opacity: 0,
                          transform: 'translateY(100%)',
                          transition: 'opacity 0.3s, transform 0.3s',
                        }}
                      >
                        <Typography variant="body2" component="div">
                          {video.description}
                        </Typography>
                      </Box>
                      {progress > 0 && (
                        <Box
                          sx={{
                            position: 'absolute',
                            bottom: 0,
                            left: 0,
                            right: 0,
                            backgroundColor: 'rgba(0, 0, 0, 0.6)',
                          }}
                        >
                          <LinearProgress
                            variant="determinate"
                            value={progress}
                            sx={{ height: '5px' }}
                          />
                          {isWatched && (
                            <Typography
                              variant="caption"
                              sx={{ color: 'white', textAlign: 'center', display: 'block' }}
                            >
                              Already Watched
                            </Typography>
                          )}
                        </Box>
                      )}
                      {video.owned ? (
                        <IconButton
                          className="play-button"
                          onClick={() => handleOpenOverlay(video)}
                          sx={{
                            position: 'absolute',
                            border: '2px solid white',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            color: 'white',
                            backgroundColor: 'rgba(0, 0, 0, 0.6)',
                            opacity: 1,
                            transition: 'transform 0.3s, background-color 0.3s, opacity 0.3s',
                            '&:hover': {
                              backgroundColor: 'rgba(0, 0, 0, 0.8)',
                              transform: 'translate(-50%, -50%) scale(1.2)',
                            },
                          }}
                        >
                          <PlayArrowIcon sx={{ fontSize: 40 }} />
                        </IconButton>
                      ) : (
                        <Box
                          sx={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            color: 'white',
                            padding: '1rem',
                            textAlign: 'center',
                          }}
                        >
                          <LockIcon sx={{ fontSize: 40, color: 'white' }} />
                          <Typography variant="body2" component="div" sx={{ marginTop: '0.5rem' }}>
                            Get {video.products.join(', ')} to unlock this video
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  </Card>
                );
              })}
          </Box>
        </Box>
      ))}

      <Snackbar
        open={notification.open}
        autoHideDuration={6000}
        onClose={() => setNotification({ ...notification, open: false })}
      >
        <Alert
          onClose={() => setNotification({ ...notification, open: false })}
          severity={notification.severity}
        >
          {notification.message}
        </Alert>
      </Snackbar>

      {isOverlayOpen && selectedVideoData && (
        <VPlayerOverlay
          identifier={selectedVideoData.identifier}
          progress={selectedVideoData.progress} 
          onClose={handleCloseOverlay}
          isPromo={false}
        />
      )}
    </Box>
  );

  const renderLoginForm = () => (

    
    <Box sx={{ textAlign: 'center', padding: '2rem', maxWidth:'600px', margin:'auto' , marginTop: '50px' }}>
      <img src={pslogo} alt="Logo" style={{ height: '30px', marginBottom:'50px' }} />
      <Typography variant="h5" component="div" sx={{ marginBottom: '2rem' }}>
        PLEASE LOGIN TO ACCESS YOUR VIDEOS
      </Typography>
      <form onSubmit={handleLogin}>
        <Box sx={{ marginBottom: '1rem' }}>
          <TextField
            label="Email"
            variant="outlined"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            fullWidth
            name="email"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Box>
        <Box sx={{ marginBottom: '1rem' }}>
          <TextField
            label="Password"
            type={showPassword ? 'text' : 'password'}
            variant="outlined"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            fullWidth
            name="password"
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={handlePasswordVisibilityToggle}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Button type="submit" variant="contained" style={{ background: '#1f1f1f', color: 'whitesmoke' }}>
          Login
        </Button>
      </form>
      <Snackbar
        open={notification.open}
        autoHideDuration={6000}
        onClose={() => setNotification({ ...notification, open: false })}
      >
        <Alert
          onClose={() => setNotification({ ...notification, open: false })}
          severity={notification.severity}
        >
          {notification.message}
        </Alert>
      </Snackbar>
    </Box>
          
  );

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />

      {/* Header if userData exists */}
      {userData && (
        <Box
          sx={{
            width: '100%',
            bgcolor: 'background.paper',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            px: 2,
            position: 'fixed',
            top: 0,
            left: 0,
            zIndex: 1000,
          }}
        >
          {isDesktop  ? (
            <Box sx={{ position: 'absolute', left: '50%', transform: 'translateX(-50%)', height: '20px' }}>
              <img src={pslogo} alt="Logo" style={{ height: '20px' }} />
            </Box>
          ) : (
            <img src={pslogo} alt="Logo" style={{ height: '20px' }} />
          )}

          <Button color="inherit" onClick={handleLogout} sx={{ marginLeft: 'auto' }}>
            Logout
          </Button>
        </Box>
      )}


     {!secret && (
        userData ? renderMainContent() : renderLoginForm()
      )}

      {(userData && loading) && (
        <Box sx={{
          position: 'fixed',
          top:0,
          left:0,
          width:'100%',
          height:'100%',
          zIndex:9999,
          backgroundColor:'black'
        }}>

<Backdrop
    open={true}
    sx={{
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      zIndex: 0,
      backgroundColor: 'rgba(0,0,0,1)' // half-transparent black
    }}
  >
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <CircularProgress color="inherit" />
      {/* You can add optional loading text or other content here */}
    </Box>
  </Backdrop>
          <LoadingAnimation isVisible={true} />
         
          
        </Box>
      )}
    </ThemeProvider>
  );
};

export default Home;
