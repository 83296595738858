import React, { useEffect, useRef, useState } from 'react';
import { 
  Box, 
  Typography, 
  Button, 
  TextField, 
  IconButton, 
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import { Add as AddIcon, Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import axios from 'axios';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';

const PreviewPlayer = ({ hlsUrl, mp4Url, subtitles, videoData, identifier, onUpdate }) => {
  const videoRef = useRef(null);
  const playerRef = useRef(null);
  const [mounted, setMounted] = useState(false);
  const [currentTime, setCurrentTime] = useState('0:00');
  const [duration, setDuration] = useState('0:00');
  const [currentTimeSeconds, setCurrentTimeSeconds] = useState(0);
  const playerId = useRef(`videojs-player-${Math.random().toString(36).substring(2, 11)}`);

  // Chapter management state
  const [chapters, setChapters] = useState(videoData.chapters || []);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [editingChapter, setEditingChapter] = useState(null);
  const [newChapterTitle, setNewChapterTitle] = useState('');

  // Thumbnail capture state
  const canvasRef = useRef(null);
  const [thumbnailSrc, setThumbnailSrc] = useState(null);

  const formatTime = (seconds) => {
    if (isNaN(seconds) || seconds === Infinity) return '0:00';
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    if (hours > 0) {
      return `${hours}:${minutes.toString().padStart(2, '0')}:${remainingSeconds
        .toString()
        .padStart(2, '0')}`;
    }
    return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  const handleAddChapter = () => {
    setEditingChapter(null);
    setNewChapterTitle('');
    setDialogOpen(true);
  };

  const handleEditChapter = (chapter, index) => {
    setEditingChapter({ ...chapter, index });
    setNewChapterTitle(chapter.title);
    setDialogOpen(true);
  };

  const handleSaveChapter = async () => {
    if (!newChapterTitle.trim()) return;

    let updatedChapters;
    if (editingChapter) {
      // Edit existing chapter
      updatedChapters = chapters.map((ch, idx) =>
        idx === editingChapter.index 
          ? { ...ch, title: newChapterTitle }
          : ch
      );
    } else {
      // Add new chapter
      const newChapter = {
        time: currentTimeSeconds,
        title: newChapterTitle
      };
      updatedChapters = [...chapters, newChapter].sort((a, b) => a.time - b.time);
    }

    try {
      const updatedData = {
        ...videoData,
        chapters: updatedChapters,
        password: localStorage.getItem('password')
      };

      await axios.post('https://learn.philspeiser.com/api/addupdate', updatedData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      setChapters(updatedChapters);
      if (onUpdate) {
        onUpdate(updatedData);
      }
    } catch (error) {
      console.error('Error saving chapter:', error);
    }

    setDialogOpen(false);
    setNewChapterTitle('');
    setEditingChapter(null);
  };

  const handleDeleteChapter = async (index) => {
    const updatedChapters = chapters.filter((_, idx) => idx !== index);

    try {
      const updatedData = {
        ...videoData,
        chapters: updatedChapters,
        password: localStorage.getItem('password')
      };

      await axios.post('https://learn.philspeiser.com/api/addupdate', updatedData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      setChapters(updatedChapters);
      if (onUpdate) {
        onUpdate(updatedData);
      }
    } catch (error) {
      console.error('Error deleting chapter:', error);
    }
  };

  useEffect(() => {
    setMounted(true);
    return () => setMounted(false);
  }, []);

  useEffect(() => {
    if (!mounted) return;

    const initializePlayer = () => {
      if (!videoRef.current) return;

      if (playerRef.current) {
        playerRef.current.dispose();
        playerRef.current = null;
      }

      const videoElement = videoRef.current;
      if (!videoElement) return;

      const player = videojs(videoElement, {
        controls: true,
        fluid: true,
        responsive: true,
        html5: {
          vhs: {
            enableLowInitialPlaylist: false,
            smoothQualityChange: true,
            overrideNative: false,
            initialBandwidth: 6000000,
          }
        },
        sources: [
          {
            src: hlsUrl,
            type: 'application/x-mpegURL'
          },
          {
            src: mp4Url,
            type: 'video/mp4'
          }
        ]
      });

      player.on('timeupdate', () => {
        const ct = player.currentTime();
        setCurrentTime(formatTime(ct));
        setCurrentTimeSeconds(ct);
        setDuration(formatTime(player.duration()));
      });

      player.on('durationchange', () => {
        setDuration(formatTime(player.duration()));
      });

      if (subtitles && subtitles.length > 0) {
        subtitles.forEach(subtitle => {
          player.addRemoteTextTrack({
            kind: 'subtitles',
            srclang: subtitle.srclang,
            label: subtitle.label,
            src: subtitle.src,
            default: subtitle.default
          }, false);
        });
      }

      playerRef.current = player;
    };

    const timeoutId = setTimeout(initializePlayer, 100);

    return () => {
      clearTimeout(timeoutId);
      if (playerRef.current) {
        try {
          playerRef.current.dispose();
        } catch (e) {
          console.warn('Error disposing video player:', e);
        }
        playerRef.current = null;
      }
    };
  }, [hlsUrl, mp4Url, subtitles, mounted]);

  // Convert dataURL to Blob
  const dataURLToBlob = (dataURL) => {
    const byteString = atob(dataURL.split(',')[1]);
    const mimeString = dataURL.split(',')[0].split(':')[1].split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  };

  const captureThumbnail = (time) => {
    if (!playerRef.current) return;

    playerRef.current.currentTime(time);

    const onSeeked = () => {
      const videoElement = document.querySelector(`#${playerId.current} video.vjs-tech`);
      const canvas = canvasRef.current;
      if (!canvas || !videoElement) return;

      const ctx = canvas.getContext('2d');
      canvas.width = videoElement.videoWidth;
      canvas.height = videoElement.videoHeight;
      ctx.drawImage(videoElement, 0, 0, canvas.width, canvas.height);

      const dataURL = canvas.toDataURL('image/png');
      setThumbnailSrc(dataURL);

      playerRef.current.off('seeked', onSeeked);
    };

    playerRef.current.one('seeked', onSeeked);
  };

  const uploadThumbnail = async () => {
    if (!thumbnailSrc) return;

    try {
      const blob = dataURLToBlob(thumbnailSrc);

      const formData = new FormData();
      formData.append('file', blob, 'thumbnail.png');
      formData.append('identifier', identifier);
      formData.append('password', localStorage.getItem('password'));

      const uploadResponse = await axios.put(
        'https://serve.philspeiser.com/api/uploadthumbnail',
        formData,
        { headers: { 'Content-Type': 'multipart/form-data' } }
      );

      const { url } = uploadResponse.data;
      const updatedData = {
        ...videoData,
        thumbnailUrl: url,
        password: localStorage.getItem('password'),
      };

      const updateResponse = await axios.post(
        'https://learn.philspeiser.com/api/addupdate',
        updatedData,
        { headers: { 'Content-Type': 'application/json' } }
      );

      if (onUpdate) {
        onUpdate(updateResponse.data.data);
      }
    } catch (error) {
      console.error('Error uploading thumbnail:', error);
    }
  };

  if (!mounted) return null;

  return (
    <Box sx={{ width: '100%', maxWidth: '100%', margin: '0 auto' }}>
      <Box
        sx={{
          position: 'relative',
          aspectRatio: '16/9',
          '& .video-js': {
            width: '100%',
            height: '100%',
            position: 'absolute',
            top: 0,
            left: 0,
            backgroundColor: 'transparent',
          },
          '& .vjs-tech': {
            position: 'absolute',
          },
          '& .vjs-big-play-button': {
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          },
          '& .vjs-control-bar': {
            backgroundColor: 'rgba(0,0,0,0.7)',
          },
        }}
      >
        <div data-vjs-player>
          <video
            ref={videoRef}
            id={playerId.current}
            className="video-js vjs-big-play-centered"
            playsInline
          >
            <p className="vjs-no-js">
              To view this video please enable JavaScript, and consider upgrading to a
              web browser that supports HTML5 video
            </p>
          </video>
        </div>
      </Box>

      {/* Time Display */}
      <Box sx={{ mt: 1, p: 1, bgcolor: 'background.paper', borderRadius: 1 }}>
        <Typography variant="body2" color="text.secondary" align="center">
          Current Time: {currentTime} / {duration}
        </Typography>
      </Box>

      {/* Buttons for thumbnail capture and upload */}
      <Box sx={{ mt: 2, display: 'flex', gap: 2 }}>
        <Button 
          variant="contained" 
          color="secondary" 
          onClick={() => captureThumbnail(currentTimeSeconds)}
        >
          Capture Thumbnail at Current Time
        </Button>
        
        {thumbnailSrc && (
          <Button 
            variant="contained" 
            color="primary" 
            onClick={uploadThumbnail}
          >
            Set as Video Thumbnail
          </Button>
        )}
      </Box>

      {/* Display Captured Thumbnail */}
      {thumbnailSrc && (
        <Box sx={{ mt: 2 }}>
          <Typography variant="h6">Captured Thumbnail:</Typography>
          <img src={thumbnailSrc} alt="Thumbnail" style={{ maxWidth: '100%', height: 'auto' }} />
        </Box>
      )}

      <canvas ref={canvasRef} style={{ display: 'none' }} />

      {/* Chapters Section */}
      <Box sx={{ mt: 2, bgcolor: 'background.paper', borderRadius: 1, p: 2 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
          <Typography variant="h6">Chapters</Typography>
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={handleAddChapter}
          >
            Add Chapter at Current Time
          </Button>
        </Box>

        <List>
          {chapters.map((chapter, index) => (
            <ListItem key={index} divider>
              <ListItemText
                primary={chapter.title}
                secondary={`Time: ${formatTime(chapter.time)}`}
              />
              <ListItemSecondaryAction>
                <IconButton 
                  edge="end" 
                  aria-label="edit"
                  onClick={() => handleEditChapter(chapter, index)}
                  sx={{ mr: 1 }}
                >
                  <EditIcon />
                </IconButton>
                <IconButton 
                  edge="end" 
                  aria-label="delete"
                  onClick={() => handleDeleteChapter(index)}
                >
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </Box>

      {/* Chapter Dialog */}
      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogTitle>
          {editingChapter ? 'Edit Chapter' : 'Add New Chapter'}
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Chapter Title"
            fullWidth
            variant="outlined"
            value={newChapterTitle}
            onChange={(e) => setNewChapterTitle(e.target.value)}
          />
          {!editingChapter && (
            <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
              Chapter will be added at: {currentTime}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleSaveChapter} variant="contained" color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default PreviewPlayer;
