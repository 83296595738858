import React, { useState, useEffect } from 'react';
import { Box, keyframes } from '@mui/material';
import psstrip from './psstrip.png'; // Ensure the path is correct

// Define the keyframes outside the component to prevent re-creation on each render
const loaderAnimation = keyframes`
  0% { background-position: 0 0; }
  100% { background-position: 0 -1750px; }
`;

const LoadingAnimation = React.memo(({ onAnimationEnd }) => {
  const [isVisible, setIsVisible] = useState(true); // Control visibility
  const [fadeOut, setFadeOut] = useState(false); // Control fade-out

  useEffect(() => {
    if (!isVisible) {
      // Call onAnimationEnd callback once fully hidden
      onAnimationEnd && onAnimationEnd();
    }
  }, [isVisible, onAnimationEnd]);

  const handleAnimationEnd = () => {
    // Start fade-out when animation ends
    setFadeOut(true);
    // After transition, hide the component
    setTimeout(() => setIsVisible(false), 500); // Match duration to transition time
  };

  return isVisible ? (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        backgroundColor: fadeOut ? 'transparent' : 'black',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 2000,
        opacity: fadeOut ? 0 : 1,
        pointerEvents: fadeOut ? 'none' : 'auto',
        transition: 'opacity 0.5s ease, background-color 0.5s ease',
      }}
    >
      <Box
        sx={{
          width: '125px',
          height: '25px',
          animation: `${loaderAnimation} 2s steps(70) 1 forwards`, // Play once and retain the final state
          backgroundImage: `url(${psstrip})`,
          backgroundSize: '125px 1775px',
          backgroundRepeat: 'no-repeat',
        }}
        onAnimationEnd={handleAnimationEnd} // Trigger fade-out on animation end
      />
    </Box>
  ) : null;
});

export default LoadingAnimation;
