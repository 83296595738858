import React, { useEffect, useRef, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import axios from 'axios';
import { Typography, Box, Button, Menu, MenuItem } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';
import loader from './loader.png';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import './customVideoStyles.css';
import Hls from 'hls.js';
import pslogo from './pslogo.png';
import Chapters from './Chapters';

const VPlayer = () => {
  const { videoName } = useParams();
  const location = useLocation();
  const [isPromo, setIsPromo] = useState(false);
  const [videoData, setVideoData] = useState(null);
  const [loading, setLoading] = useState(true);
  const videoRef = useRef(null);
  const playerRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedSubtitle, setSelectedSubtitle] = useState(null);
  const [currentTime, setCurrentTime] = useState(0);
  const [isPlayerReady, setIsPlayerReady] = useState(false);
  const [videoDuration, setVideoDuration] = useState(0);
  const DataReady = useRef(null);
  const [showHider, setshowHider] = useState(false);
  const [isUnlocked, setIsUnlocked] = useState(false);


  useEffect(() => {
    // Parse URL parameters and set isPromo state
    const searchParams = new URLSearchParams(location.search);
    setIsPromo(searchParams.get('promo') === 'true');
    setIsUnlocked(searchParams.get('unlckd') === 'true');
    console.log(searchParams);
    console.log(searchParams.get('unlckd'));
    
  }, [location]);

  useEffect(() => {
    const loadingTimer = setTimeout(() => {
      setLoading(false);
    }, 900);

    return () => clearTimeout(loadingTimer);
  }, []);

  useEffect(() => {


    
    if (playerRef.current && isPlayerReady) {
      const player = playerRef.current;

      const updateTime = () => {
        const newTime = Math.floor(player.currentTime());
        setCurrentTime(newTime);
      };

      player.on('timeupdate', updateTime);
      player.on('play', updateTime); // Update time when video starts playing

      // Manually trigger time update every second
      const intervalId = setInterval(updateTime, 1000);

      return () => {
        player.off('timeupdate', updateTime);
        player.off('play', updateTime);
        clearInterval(intervalId);
      };
    }
  }, [playerRef, isPlayerReady]);

  const orderSubtitles = (videoData) => {
    if (!videoData || !videoData.subtitles || !Array.isArray(videoData.subtitles)) {
      return [];
    }

    // Define the preferred order of languages
    const languageOrder = ['en', 'de', 'fr', 'it', 'es'];

    // Create a map for O(1) lookup of language positions
    const orderMap = Object.fromEntries(
      languageOrder.map((lang, index) => [lang, index])
    );

    // Sort the subtitles array
    const orderedSubtitles = [...videoData.subtitles].sort((a, b) => {
      const indexA = orderMap[a.srclang] ?? Number.MAX_SAFE_INTEGER;
      const indexB = orderMap[b.srclang] ?? Number.MAX_SAFE_INTEGER;
      return indexA - indexB;
    });

    return orderedSubtitles;
  };

  


  const fetchUserOwned = async () => {

    console.log('unlocked', isUnlocked);

    if (isUnlocked) {
      setshowHider(false);
      return;
    }


    const storedUserData = JSON.parse(localStorage.getItem('userData'));

    if (!storedUserData) {
      setshowHider(true);
      return;
    }

    console.log(storedUserData);


    if (storedUserData.token && !isUnlocked) {
    try {

      //console.log(storedUserData);
      const response = await axios.post('https://psproducts.vercel.app/api/ownedfortoken', {
        token: storedUserData.token,
      });

      //console.log(videoData);

      //console.log(videoData.products);

      if (videoData.products) {
        videoData.products = videoData.products.split(/\s*,\s*/).map((product) => product.trim());
      } else {
        videoData.products = [];
      }

      let owned = false;
      if (response.data.subscription === 'active') {
        owned = true;
      } else {
        owned = videoData.products.some((product) => response.data.products.includes(product));
      }

      if (owned === false) {
        setshowHider(true);
      }
      else {
        setshowHider(false);
      }

      


    } catch (err) {
      console.error('Error fetching user data', err);
    }
  }
  };

  // Modified useEffect with ordered subtitles
  useEffect(() => {
    const fetchVideoData = async () => {
      try {
        const response = await axios.get(
          `https://learn.philspeiser.com/api/getvideo?identifier=${videoName}`
        );
        const videoInfo = response.data;

        

      

        if (videoInfo) {

          // Set thumbnail
          videoInfo.thumbnail = videoInfo.thumbnailUrl ?? 'https://i6bcizwg1mpghn9b.public.blob.vercel-storage.com/videothumbnails/fallback-bDkvltu910WiV6ZVUREx2y3iICi8PQ.jpg';

          // Order subtitles
          videoInfo.subtitles = orderSubtitles(videoInfo);

          setVideoData(videoInfo);
          //console.log('Video data:', videoInfo);

         
          
        } else {
          console.error('Video not found');
        }
      } catch (error) {
        console.error('Error fetching video data', error);
      }
    };

    fetchVideoData();
  }, [videoName]);

  useEffect(() => {
    return () => {
      if (playerRef.current) {
        playerRef.current.dispose();
        playerRef.current = null;
      }
    };
  }, []);


  useEffect(() => {
    console.log('unlocked', isUnlocked);

    if (videoData && DataReady.current !== 1 && videoData.public !== "true" && isUnlocked === false) {
      fetchUserOwned();
      DataReady.current = 1;
    }
  }, [videoData]);


  

  const handleChapterClick = (time) => {
    if (playerRef.current) {
      // Ensure the time is within video duration
      const safeTime = time < videoDuration ? time : videoDuration - 1;
      if (time >= videoDuration) {
        console.warn(`Chapter time (${time}) exceeds video duration (${videoDuration}). Adjusting to ${safeTime}.`);
      }

      playerRef.current.currentTime(Math.round(safeTime));
      playerRef.current.play();
    }
  };

  const handleSubtitleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSubtitleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleSubtitleSelect = (subtitle) => {
    setSelectedSubtitle(subtitle);
    if (playerRef.current) {
      const tracks = playerRef.current.textTracks();
      for (let i = 0; i < tracks.length; i++) {
        const track = tracks[i];
        if (track.kind === 'subtitles') {
          if (subtitle === null) {
            track.mode = 'disabled';
          } else {
            track.mode = track.language === subtitle.srclang ? 'showing' : 'hidden';
          }
        }
      }
    }
    handleSubtitleMenuClose();
  };

  return (
    <ThemeProvider theme={theme}>
      {loading && (
        <Box
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            bgcolor: 'black',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 2000,
          }}
        >
          <Box
            sx={{
              width: '45px',
              height: '45px',
              animation: 'loader-animation 0.8s steps(19) infinite',
              backgroundImage: `url(${loader})`,
              backgroundSize: '45px 885px',
              backgroundRepeat: 'no-repeat',
            }}
          />
          <style>
            {`
              @keyframes loader-animation {
                0% { background-position: 0 0; }
                100% { background-position: 0 -885px; }
              }
            `}
          </style>
        </Box>
      )}

      {showHider && (
       <Box
       sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        bgcolor: 'black',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 1999,
      }}>
        <Box sx={{ display:'flex', flexDirection:'column', alignItems:'center'}}>
         <Typography variant="h6" component="h2" sx={{ color: 'white', mt: 0, padding: '25px', textAlign:'center' }}>
              DO YOU OWN THE PLUGIN THIS VIDEO IS ABOUT?
            </Typography> 
            <Button variant="contained" onClick={() => window.location.href = 'https://learn.philspeiser.com/'} sx={{ backgroundColor: '#202020', color: '#CFCFCF', width: '50px', margin: '10px'}}> LOGIN </Button>
            {videoData.link && videoData.linktext && (
            <a
              href={videoData.link}
              target="_blank"
              rel="noopener noreferrer"
              className="video-link"
              style={{
                color: '#CFCFCF',
                textDecoration: 'none',
                backgroundColor: '#202020',
                padding: '10px 20px',
                borderRadius: '5px',
                transition: 'background-color 0.3s',
                display: 'inline-block',
                marginTop: '40px',
                fontWeight: 'bold',
              }}
              onMouseEnter={(e) => (e.target.style.backgroundColor = '#303030')}
              onMouseLeave={(e) => (e.target.style.backgroundColor = '#202020')}
            >
              {videoData.linktext}
            </a>
          )}
      </Box>
      </Box>
      )}

      <Box
        sx={{
          width: '100%',
          height: '30px',
          bgcolor: 'background.paper',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          px: 2,
          position: 'fixed',
          top: 0,
          left: 0,
          zIndex: 1000,
        }}
      >
        <img src={pslogo} alt="Logo" style={{ height: '20px' }} />
      </Box>

      {videoData && (
        <Box sx={{ textAlign: 'center', width: { xs: '100%', sm: '65%' }, margin: 'auto', mt: '60px', maxWidth: '1500px' }}>
          <div data-vjs-player>
            <video
              ref={(el) => {
                videoRef.current = el;

                if (
                  el &&
                  videoData &&
                  videoData.assets &&
                  (videoData.assets.hls || videoData.assets.mp4) &&
                  !playerRef.current
                ) {
                  const videoElement = el;

                  playerRef.current = videojs(videoElement, {
                    controls: true,
                    autoplay: false,
                    preload: 'auto',
                    poster: videoData.thumbnail,
                    responsive: true,
                    crossOrigin: 'anonymous',
                    fluid: true,
                    controlBar: {
                      pictureInPictureToggle: false,
                      captionsButton: false,
                      subtitlesButton: false,
                      subsCapsButton: false,
                    },
                  });

                  // Add subtitles
                  if (videoData.subtitles && videoData.subtitles.length > 0) {
                    videoData.subtitles.forEach((subtitle) => {
                      playerRef.current.addRemoteTextTrack(
                        {
                          kind: 'subtitles',
                          label: subtitle.label,
                          srclang: subtitle.srclang,
                          src: subtitle.src,
                          default: subtitle.default,
                          crossOrigin: 'anonymous',
                        },
                        false
                      );
                    });
                  }

                  // Initialize HLS or set MP4 source
                  if (Hls.isSupported() && videoData.assets.hls) {
                    const hls = new Hls({
                      startLevel: 3,
                      capLevelToPlayerSize: false,
                    });
                    hls.loadSource(videoData.assets.hls);
                    hls.attachMedia(videoElement);
                  } else if (videoElement.canPlayType('application/vnd.apple.mpegurl') && videoData.assets.hls) {
                    videoElement.src = videoData.assets.hls;
                  } else if (videoData.assets.mp4) {
                    videoElement.src = videoData.assets.mp4;
                  } else {
                    console.error('No compatible video format found');
                  }

                  // Listen for metadata to get video duration
                  playerRef.current.on('loadedmetadata', () => {
                    const duration = playerRef.current.duration();
                    setVideoDuration(duration);
                    

                    // Adjust chapters if necessary
                    if (videoData.chapters && videoData.chapters.length > 0) {
                      const adjustedChapters = videoData.chapters.map((chapter, index) => {
                        if (index === videoData.chapters.length - 1 && chapter.time >= duration) {
                          console.warn(`Adjusting last chapter time from ${chapter.time} to ${duration - 1}.`);
                          return { ...chapter, time: duration - 1 };
                        }
                        return chapter;
                      });
                      setVideoData((prevData) => ({
                        ...prevData,
                        chapters: adjustedChapters,
                      }));
                    }
                  });

                  // Error handling
                  playerRef.current.on('error', () => {
                    const error = playerRef.current.error();
                    console.error('Video.js Error:', error);
                    // Optionally, display a user-friendly message or attempt to recover
                  });

                  setIsPlayerReady(true);
                  //console.log("Player initialized");
                }
              }}
              className="video-js vjs-big-play-centered custom-player"
              crossOrigin="anonymous"
            />
          </div>

          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2, gap: 2, alignItems: 'center', padding: { xs: '10px', sm: '0px' } }}>
            {videoData.subtitles && videoData.subtitles.length > 0 && (
              <Box>
                <Button
                  variant="contained"
                  onClick={handleSubtitleMenuOpen}
                  sx={{ backgroundColor: '#202020', color: '#CFCFCF', minWidth: '150px' }}
                >
                  {selectedSubtitle ? `Subtitles: ${selectedSubtitle.label}` : 'Subtitles: OFF'}
                </Button>
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleSubtitleMenuClose}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  sx={{ marginBottom: '5px' }} // Adjust as needed for spacing
                >
                  <MenuItem onClick={() => handleSubtitleSelect(null)}>
                    Off
                  </MenuItem>
                  {videoData.subtitles.map((subtitle) => (
                    <MenuItem
                      key={subtitle.srclang}
                      onClick={() => handleSubtitleSelect(subtitle)}
                    >
                      {subtitle.label}
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
            )}

            {videoData.chapters && videoData.chapters.length > 0 && (
              <Chapters
                chapters={videoData.chapters}
                currentTime={currentTime}
                onChapterClick={handleChapterClick}
              />
            )}
          </Box>

          {videoData.title && (
            <Typography variant="h6" component="h2" sx={{ color: 'white', mt: 0, padding: { xs: 2, sm:1 }, paddingBottom: { xs: 0, sm: 0 } }}>
              {videoData.title}
            </Typography>
          )}
          {videoData.description && (
            <Typography variant="body2" sx={{ mt: 1, color: 'white', padding: { xs: 2 }, paddingTop: { xs: 0, sm: 0 } }}>
              {videoData.description}
            </Typography>
          )}
          {isPromo && videoData.link && videoData.linktext && (
            <a
              href={videoData.link}
              target="_blank"
              rel="noopener noreferrer"
              className="video-link"
              style={{
                color: '#CFCFCF',
                textDecoration: 'none',
                backgroundColor: '#202020',
                padding: '10px 20px',
                borderRadius: '5px',
                transition: 'background-color 0.3s',
                display: 'inline-block',
                marginTop: '0px',
                fontWeight: 'bold',
              }}
              onMouseEnter={(e) => (e.target.style.backgroundColor = '#303030')}
              onMouseLeave={(e) => (e.target.style.backgroundColor = '#202020')}
            >
              {videoData.linktext}
            </a>
          )}
        </Box>
      )}
    </ThemeProvider>
  );
};

export default VPlayer;
