import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { Typography, Box, Button, Menu, MenuItem, IconButton } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close'; // For the close button icon
import theme from './theme';
import loader from './loader.png';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import './customVideoStyles.css';
import Hls from 'hls.js';
import pslogo from './pslogo.png';
import Chapters from './Chapters';
import TheCB from './TheCB';
import { debounce } from 'lodash';


const VPlayerOverlay = ({ identifier, onClose, progress = 0, isPromo }) => {
  const [videoData, setVideoData] = useState(null);
  const [loading, setLoading] = useState(true);
  const videoRef = useRef(null);
  const playerRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedSubtitle, setSelectedSubtitle] = useState(null);
  const [currentTime, setCurrentTime] = useState(0);
  const [isPlayerReady, setIsPlayerReady] = useState(false);
  const [videoDuration, setVideoDuration] = useState(0);
  const lastProgressUpdate = useRef(0);


  const handleProgressUpdate = () => {
    let time = Math.round(currentTime);
    let duration = Math.round(videoDuration);
  
    if (playerRef.current) {
      time = Math.round(playerRef.current.currentTime());
      duration = Math.round(playerRef.current.duration());
    }
  
    console.log('handleProgressUpdate', time, duration);
  
    if (duration > 0) {
      const progress = Math.floor((time / duration) * 100);
      console.log('Progress:', progress);
      if (progress !== lastProgressUpdate.current) {
        lastProgressUpdate.current = progress;
        const storedUserData = JSON.parse(localStorage.getItem('userData'));
        console.log(storedUserData.email, storedUserData.displayName, identifier, progress);
        if (storedUserData && storedUserData.email) {
          axios.post('https://serve.philspeiser.com/api/setvideoprogress', {
            email: storedUserData.email,
            name: storedUserData.displayName,
            videoId: identifier,
            progress: progress,
          });
        }
      }
    } else {
      console.log('Cannot calculate progress because videoDuration is 0');
    }
  };
  
  
  const debouncedHandleProgressUpdate = debounce(handleProgressUpdate, 1000);

  useEffect(() => {
    if (isPlayerReady && playerRef.current && videoDuration > 0) {
      const seekTime = (progress / 100) * videoDuration;
      
      // Only seek if the seekTime is above 10 seconds and below 95% of the duration
      if (seekTime >= 10 && progress < 95) {
        playerRef.current.currentTime(seekTime);
        console.log(`Seeking to ${seekTime} seconds based on progress of ${progress}%`);
      }
    }
  }, [isPlayerReady, videoDuration, progress]);

  useEffect(() => {
    const loadingTimer = setTimeout(() => {
      setLoading(false);
    }, 900);

    return () => clearTimeout(loadingTimer);
  }, []);

  useEffect(() => {
    if (playerRef.current && isPlayerReady) {
      const player = playerRef.current;
  
      const updateTime = () => {
        const newTime = Math.floor(player.currentTime());
        setCurrentTime(newTime);
      };
  
      player.on('timeupdate', updateTime);
      player.on('play', updateTime);
  
      const intervalId = setInterval(() => {
        if (player && !player.paused()) {
          debouncedHandleProgressUpdate();
        }
      }, 10000); // Update progress every 10 seconds
  
      return () => {
        player.off('timeupdate', updateTime);
        player.off('play', updateTime);
        clearInterval(intervalId);
      };
    }
  }, [playerRef, isPlayerReady]);


  useEffect(() => {
    const handleBeforeUnload = () => {
      handleProgressUpdate();
    };
  
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [currentTime]);
  

  const orderSubtitles = (videoData) => {
    if (!videoData || !videoData.subtitles || !Array.isArray(videoData.subtitles)) {
      return [];
    }

    const languageOrder = ['en', 'de', 'fr', 'it', 'es'];

    const orderMap = Object.fromEntries(
      languageOrder.map((lang, index) => [lang, index])
    );

    const orderedSubtitles = [...videoData.subtitles].sort((a, b) => {
      const indexA = orderMap[a.srclang] ?? Number.MAX_SAFE_INTEGER;
      const indexB = orderMap[b.srclang] ?? Number.MAX_SAFE_INTEGER;
      return indexA - indexB;
    });

    return orderedSubtitles;
  };

  useEffect(() => {
    const fetchVideoData = async () => {
      try {
        const response = await axios.get(
          `https://learn.philspeiser.com/api/getvideo?identifier=${identifier}`
        );
        const videoInfo = response.data;

        if (videoInfo) {
          // Set thumbnail
          videoInfo.thumbnail =
            videoInfo.thumbnailUrl ??
            'https://i6bcizwg1mpghn9b.public.blob.vercel-storage.com/videothumbnails/fallback-bDkvltu910WiV6ZVUREx2y3iICi8PQ.jpg';

          // Order subtitles
          videoInfo.subtitles = orderSubtitles(videoInfo);

          setVideoData(videoInfo);
        } else {
          console.error('Video not found');
        }
      } catch (error) {
        console.error('Error fetching video data', error);
      }
    };

    fetchVideoData();
  }, [identifier]);

  useEffect(() => {
    return () => {
      if (playerRef.current) {
        handleProgressUpdate(); // Update progress on component unmount
        playerRef.current.dispose();
        playerRef.current = null;
      }
    };
  }, []);
  
  

  const handleChapterClick = (time) => {
    if (playerRef.current) {
      const safeTime = time < videoDuration ? time : videoDuration - 1;
      if (time >= videoDuration) {
        console.warn(
          `Chapter time (${time}) exceeds video duration (${videoDuration}). Adjusting to ${safeTime}.`
        );
      }

      playerRef.current.currentTime(Math.round(safeTime));
      playerRef.current.play();
    }
  };

  const handleSubtitleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSubtitleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleSubtitleSelect = (subtitle) => {
    setSelectedSubtitle(subtitle);
    if (playerRef.current) {
      const tracks = playerRef.current.textTracks();
      for (let i = 0; i < tracks.length; i++) {
        const track = tracks[i];
        if (track.kind === 'subtitles') {
          if (subtitle === null) {
            track.mode = 'disabled';
          } else {
            track.mode = track.language === subtitle.srclang ? 'showing' : 'hidden';
          }
        }
      }
    }
    handleSubtitleMenuClose();
  };

  

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          bgcolor: 'rgba(0, 0, 0, 0.9)',
          zIndex: 3000,
          overflowY: 'auto',
        }}
      >
        {/* Close button */}
        <IconButton
          onClick={() => {
            handleProgressUpdate();
            onClose();
          }}
          sx={{
            position: 'fixed',
            top: {xs:10, sm: 20, xl: '60px'},
            right:  {xs:10, sm: 20, xl: '20%'},
            color: 'white',
            zIndex: 4000,
          }}
        >
          <CloseIcon />
        </IconButton>

        {loading && (
          <Box
            sx={{
              position: 'fixed',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              bgcolor: 'black',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: 2000,
            }}
          >
            <Box
              sx={{
                width: '45px',
                height: '45px',
                animation: 'loader-animation 0.8s steps(19) infinite',
                backgroundImage: `url(${loader})`,
                backgroundSize: '45px 885px',
                backgroundRepeat: 'no-repeat',
              }}
            />
            <style>
              {`
                @keyframes loader-animation {
                  0% { background-position: 0 0; }
                  100% { background-position: 0 -885px; }
                }
              `}
            </style>
          </Box>
        )}

        

        {videoData && (
          <Box
            sx={{
              textAlign: 'center',
              width: { xs: '100%', sm: '65%' },
              margin: 'auto',
              mt: { xs: '30px', sm: '30px',l: '5%', xl: '5%' },
              maxWidth: '1900px',
            }}
          >
            <div data-vjs-player>
              <video
                ref={(el) => {
                  videoRef.current = el;

                  if (
                    el &&
                    videoData &&
                    videoData.assets &&
                    (videoData.assets.hls || videoData.assets.mp4) &&
                    !playerRef.current
                  ) {
                    const videoElement = el;

                    playerRef.current = videojs(videoElement, {
                      controls: true,
                      autoplay: true,
                      preload: 'auto',
                      poster: videoData.thumbnail,
                      responsive: true,
                      crossOrigin: 'anonymous',
                      fluid: true,
                      controlBar: {
                        pictureInPictureToggle: false,
                        captionsButton: false,
                        subtitlesButton: false,
                        subsCapsButton: false,
                      },
                    });

                    // Add subtitles
                    if (videoData.subtitles && videoData.subtitles.length > 0) {
                      videoData.subtitles.forEach((subtitle) => {
                        playerRef.current.addRemoteTextTrack(
                          {
                            kind: 'subtitles',
                            label: subtitle.label,
                            srclang: subtitle.srclang,
                            src: subtitle.src,
                            default: subtitle.default,
                            crossOrigin: 'anonymous',
                          },
                          false
                        );
                      });
                    }

                    // Initialize HLS or set MP4 source
                    if (Hls.isSupported() && videoData.assets.hls) {
                      const hls = new Hls({
                        startLevel: 3,
                        capLevelToPlayerSize: false,
                      });
                      hls.loadSource(videoData.assets.hls);
                      hls.attachMedia(videoElement);
                    } else if (
                      videoElement.canPlayType('application/vnd.apple.mpegurl') &&
                      videoData.assets.hls
                    ) {
                      videoElement.src = videoData.assets.hls;
                    } else if (videoData.assets.mp4) {
                      videoElement.src = videoData.assets.mp4;
                    } else {
                      console.error('No compatible video format found');
                    }

                    // Listen for metadata to get video duration
                    playerRef.current.on('loadedmetadata', () => {
                      const duration = playerRef.current.duration();
                      setVideoDuration(duration);

                      // Adjust chapters if necessary
                      if (videoData.chapters && videoData.chapters.length > 0) {
                        const adjustedChapters = videoData.chapters.map((chapter, index) => {
                          if (index === videoData.chapters.length - 1 && chapter.time >= duration) {
                            console.warn(
                              `Adjusting last chapter time from ${chapter.time} to ${
                                duration - 1
                              }.`
                            );
                            return { ...chapter, time: duration - 1 };
                          }
                          return chapter;
                        });
                        setVideoData((prevData) => ({
                          ...prevData,
                          chapters: adjustedChapters,
                        }));
                      }
                    });

                    // Error handling
                    playerRef.current.on('error', () => {
                      const error = playerRef.current.error();
                      console.error('Video.js Error:', error);
                      // Optionally, display a user-friendly message or attempt to recover
                    });

                    setIsPlayerReady(true);
                  }
                }}
                className="video-js vjs-big-play-centered custom-player"
                crossOrigin="anonymous"
              />
            </div>

            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                mt: 2,
                gap: 2,
                alignItems: 'center',
                padding: { xs: '10px', sm: '0px' },
              }}
            >
              {videoData.subtitles && videoData.subtitles.length > 0 && (
                <Box>
                  <Button
                    variant="contained"
                    onClick={handleSubtitleMenuOpen}
                    sx={{
                      backgroundColor: '#202020',
                      color: '#CFCFCF',
                      minWidth: '150px',
                    }}
                  >
                    {selectedSubtitle
                      ? `Subtitles: ${selectedSubtitle.label}`
                      : 'Subtitles: OFF'}
                  </Button>
                  <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleSubtitleMenuClose}
                    disablePortal
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                    transformOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                    sx={{ marginBottom: '5px' }}
                  >
                    <MenuItem onClick={() => handleSubtitleSelect(null)}>Off</MenuItem>
                    {videoData.subtitles.map((subtitle) => (
                      <MenuItem
                        key={subtitle.srclang}
                        onClick={() => handleSubtitleSelect(subtitle)}
                      >
                        {subtitle.label}
                      </MenuItem>
                    ))}
                  </Menu>
                </Box>
              )}

              {videoData.chapters && videoData.chapters.length > 0 && (
                <Chapters
                  chapters={videoData.chapters}
                  currentTime={currentTime}
                  onChapterClick={handleChapterClick}
                />
              )}
            </Box>

            {videoData.title && (
              <Typography
                variant="h6"
                component="h2"
                sx={{
                  color: 'white',
                  mt: 0,
                  padding: { xs: 2, sm: 1 },
                  paddingBottom: { xs: 0, sm: 0 },
                }}
              >
                {videoData.title}
              </Typography>
            )}
            {videoData.description && (
              <Typography
                variant="body2"
                sx={{
                  mt: 1,
                  color: 'white',
                  padding: { xs: 2 },
                  paddingTop: { xs: 0, sm: 0 },
                }}
              >
                {videoData.description}
              </Typography>
            )}
            {videoData.chatbot=='true' && (
            <TheCB identifier={identifier} timestamp={currentTime}/>
            )}

            {isPromo && videoData.link && videoData.linktext && (
              <a
                href={videoData.link}
                target="_blank"
                rel="noopener noreferrer"
                className="video-link"
                style={{
                  color: '#CFCFCF',
                  textDecoration: 'none',
                  backgroundColor: '#202020',
                  padding: '10px 20px',
                  borderRadius: '5px',
                  transition: 'background-color 0.3s',
                  display: 'inline-block',
                  marginTop: '0px',
                  fontWeight: 'bold',
                }}
                onMouseEnter={(e) => (e.target.style.backgroundColor = '#303030')}
                onMouseLeave={(e) => (e.target.style.backgroundColor = '#202020')}
              >
                {videoData.linktext}
              </a>
            )}
          </Box>
        )}
      </Box>
    </ThemeProvider>
  );
};

export default VPlayerOverlay;
