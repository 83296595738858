import React, { useState, useCallback, useEffect } from 'react';
import Cropper from 'react-easy-crop';
import axios from 'axios';
import { Button } from '@mui/material';

const ImageCropper = ({
  imageUrl: originalImageUrl,
  identifier,
  videoData,
  setVideoData,
  setNotification, // Optional, if you have a notification system
}) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [uploadedImageUrl, setUploadedImageUrl] = useState(null);
  const [editImage, setEditImage] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [displayImageUrl, setDisplayImageUrl] = useState(originalImageUrl);

  // If a user selects a new file, read it and convert to data URL
  useEffect(() => {
    if (selectedFile) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setDisplayImageUrl(e.target.result);
        setEditImage(true);
      };
      reader.readAsDataURL(selectedFile);
    } else {
      // If no file selected, fall back to original image
      setDisplayImageUrl(originalImageUrl);
    }
  }, [selectedFile, originalImageUrl]);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  // Function to get the cropped image as a Blob
  const getCroppedImg = async (imageSrc, pixelCrop) => {
    const createImage = (url) =>
      new Promise((resolve, reject) => {
        const image = new Image();
        image.crossOrigin = 'anonymous'; // Enable cross-origin access if needed
        image.src = url;
        image.onload = () => resolve(image);
        image.onerror = (error) => reject(error);
      });

    const image = await createImage(imageSrc);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    // Set canvas dimensions to the cropped area size
    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;

    // Draw the cropped image onto the canvas
    ctx.drawImage(
      image,
      pixelCrop.x,
      pixelCrop.y,
      pixelCrop.width,
      pixelCrop.height,
      0,
      0,
      pixelCrop.width,
      pixelCrop.height
    );

    // Convert the canvas to a Blob
    return new Promise((resolve, reject) => {
      canvas.toBlob(
        (blob) => {
          if (!blob) {
            reject(new Error('Canvas is empty'));
            return;
          }
          resolve(blob);
        },
        'image/jpeg',
        0.9 // Quality parameter for JPEG images
      );
    });
  };

  // Function to handle the cropping and uploading
  const handleCropAndUpload = async () => {
    try {
      // Get the cropped image as a Blob
      const croppedImageBlob = await getCroppedImg(displayImageUrl, croppedAreaPixels);

      // Prepare the form data for upload
      const formData = new FormData();
      formData.append('file', croppedImageBlob, 'cropped.jpg');
      formData.append('identifier', identifier);
      formData.append('password', localStorage.getItem('password'));

      // Upload the cropped image to the server
      const response = await axios.put(
        'https://serve.philspeiser.com/api/uploadthumbnail',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      const { url } = response.data;

      // Update the video data with the new mini thumbnail URL
      const updatedVideoData = {
        ...videoData[identifier],
        minithumbnailUrl: url,
      };

      // Send the updated video data to the server
      const updateResponse = await axios.post(
        'https://learn.philspeiser.com/api/addupdate',
        {
          ...updatedVideoData,
          password: localStorage.getItem('password'),
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      if (updateResponse.status === 200) {
        // Update the local state with the new video data
        setVideoData((prevData) => ({
          ...prevData,
          [identifier]: updatedVideoData,
        }));

        if (setNotification) {
          setNotification({
            open: true,
            message: 'Mini thumbnail uploaded and updated successfully',
            severity: 'success',
          });
        }

        setEditImage(false);
        setUploadedImageUrl(url);
      }
    } catch (error) {
      console.error('Error uploading and updating thumbnail:', error);

      if (setNotification) {
        setNotification({
          open: true,
          message: 'Failed to upload and update thumbnail.',
          severity: 'error',
        });
      }
    }
  };

  return (
    <>
      {/* Display existing mini thumbnail if available */}
      {videoData[identifier].minithumbnailUrl && !editImage && (
        <div style={{ marginBottom: '20px' }}>
          <img
            src={videoData[identifier].minithumbnailUrl}
            style={{ width: '200px', objectFit: 'cover' }}
            alt="Mini Thumbnail"
          />
          <div>
            <Button variant="outlined" color="primary" onClick={() => setEditImage(true)}>
              Edit Mini Thumbnail
            </Button>
          </div>
        </div>
      )}

      {/* If no mini thumbnail exists or user wants to edit/upload new one */}
      {(editImage || !videoData[identifier].minithumbnailUrl) && (
        <div style={{ marginBottom: '20px' }}>
          {/* File input to choose a different image */}
          <div style={{ marginBottom: '10px' }}>
            <input
              type="file"
              accept="image/*"
              onChange={(e) => {
                if (e.target.files && e.target.files.length > 0) {
                  setSelectedFile(e.target.files[0]);
                }
              }}
            />
          </div>

          {displayImageUrl && (
            <div style={{ position: 'relative', width: '100%', height: '400px' }}>
              <Cropper
                image={displayImageUrl}
                crop={crop}
                zoom={zoom}
                aspect={4 / 5} // Adjust the aspect ratio as needed
                onCropChange={setCrop}
                onZoomChange={setZoom}
                onCropComplete={onCropComplete}
              />
              {/* Controls for zooming */}
              <div style={{ position: 'absolute', bottom: '20px', left: '20px' }}>
                <input
                  type="range"
                  min={1}
                  max={3}
                  step={0.1}
                  value={zoom}
                  onChange={(e) => setZoom(e.target.value)}
                />
              </div>
              {/* Button to trigger the upload */}
              <Button
                variant="contained"
                color="primary"
                onClick={handleCropAndUpload}
                style={{ position: 'absolute', bottom: '20px', right: '20px' }}
              >
                Upload Cropped Image
              </Button>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default ImageCropper;
