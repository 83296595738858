import React, { useState, useEffect, useRef } from 'react';
import { Box, Typography, List, ListItem, ListItemButton, ListItemText, Button, Paper, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const Chapters = ({ chapters, currentTime, onChapterClick }) => {
  const [isListOpen, setIsListOpen] = useState(false);
  const [activeChapterIndex, setActiveChapterIndex] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const containerRef = useRef(null);
  const activeChapterRef = useRef(null);

  const isChapterActive = (chapterIndex) => {
    const chapter = chapters[chapterIndex];
    const nextChapter = chapters[chapterIndex + 1];
    const isActive = chapterIndex === chapters.length - 1
      ? currentTime >= chapter.time
      : currentTime >= chapter.time && currentTime < nextChapter.time;
      
    return isActive;
  };

  // Update active chapter index based on current time
  useEffect(() => {
    const newIndex = chapters.findIndex((_, index) => isChapterActive(index));
    if (newIndex !== activeChapterIndex) {
      
      setActiveChapterIndex(newIndex);
    }
  }, [currentTime, chapters, activeChapterIndex]);

  // Scroll to the new active chapter if it changes
  useEffect(() => {
    if (activeChapterRef.current && containerRef.current) {
      activeChapterRef.current.scrollIntoView({
        behavior: 'smooth',
        inline: 'center',
      });
    }
  }, [activeChapterIndex]);

  const getCurrentChapter = () => {
    return activeChapterIndex !== null ? chapters[activeChapterIndex]?.title : 'Chapters';
  };

  const toggleList = () => {
    setIsListOpen(!isListOpen);
  };

  const scrollContainer = (scrollOffset) => {
    if (containerRef.current) {
      containerRef.current.scrollBy({ left: scrollOffset, behavior: 'smooth' });
    }
  };

  return (
    <Box sx={{ width: '100%', position: 'relative', overflow:{xs:'visible', sm:'hidden' } }}>
      {isMobile ? (
        <>
          <Button 
            onClick={toggleList}
            sx={{ 
              width: '100%', 
              justifyContent: 'space-between', 
              color: 'text.primary',
              bgcolor: 'background.paper',
              '&:hover': {
                backgroundColor: 'rgba(255, 255, 255, 0.08)',
              },
            }}
          >
            <Typography noWrap>
              {getCurrentChapter()}
            </Typography>
            <Typography>{isListOpen ? '▲' : '▼'}</Typography>
          </Button>
          {isListOpen && (
            <Paper 
              elevation={3}
              sx={{
                position: 'absolute',
                bottom: '100%',
                left: 0,
                width: '100%',
                maxHeight: '300px',
                overflowY: 'auto',
                zIndex: 1000,
                bgcolor: 'background.paper',
                marginBottom: '5px',
              }}
            >
              <List>
                {chapters.map((chapter, index) => (
                  <ListItem key={index} disablePadding>
                    <ListItemButton
                      onClick={() => {
                        onChapterClick(chapter.time);
                        setIsListOpen(false);
                      }}
                      selected={isChapterActive(index)}
                      sx={{
                        '&.Mui-selected': {
                          backgroundColor: 'rgba(255, 255, 255, 0.08)',
                          '&:hover': {
                            backgroundColor: 'rgba(255, 255, 255, 0.12)',
                          },
                        },
                      }}
                    >
                      <ListItemText primary={chapter.title} />
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            </Paper>
          )}
        </>
      ) : (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Button 
            onClick={() => scrollContainer(-150)} 
            sx={{ minWidth: '40px' }}
          >
            {'<'}
          </Button>
          <Box
            ref={containerRef}
            sx={{
              display: 'flex',
              gap: 1,
              overflowX: 'auto',
              scrollSnapType: 'x mandatory',
              padding: '10px 0',
              flexGrow: 1,
              '& > *': {
                scrollSnapAlign: 'start',
              },
              // Hide the scrollbar while preserving scroll functionality
              scrollbarWidth: 'none', // Firefox
              '&::-webkit-scrollbar': {
                display: 'none', // Chrome, Safari, and Edge
              },
            }}
          >
            {chapters.map((chapter, index) => (
              <Button
                key={index}
                ref={index === activeChapterIndex ? activeChapterRef : null} // Reference for the active chapter
                onClick={() => onChapterClick(chapter.time)}
                variant={isChapterActive(index) ? 'contained' : 'text'}
                sx={{
                  backgroundColor: isChapterActive(index) ? 'primary.main' : 'transparent',
                  color: isChapterActive(index) ? 'primary.contrastText' : 'text.primary',
                  '&:hover': {
                    backgroundColor: isChapterActive(index)
                      ? 'primary.dark'
                      : 'rgba(255, 255, 255, 0.08)',
                  },
                  minWidth: '100px',
                  flexShrink: 0,
                  textAlign: 'center',
                }}
              >
                <Typography variant="body2" noWrap>
                  {chapter.title}
                </Typography>
              </Button>
            ))}
          </Box>
          <Button 
            onClick={() => scrollContainer(150)} 
            sx={{ minWidth: '40px' }}
          >
            {'>'}
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default Chapters;
